import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from "react-qr-code";
import './OrderList.css';
import apiConfig from '../../../apiConfig';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOrderId, setFilterOrderId] = useState('');
  const [filterProductName, setFilterProductName] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      
      try {
     
        const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.getAllOrders}`);

        setOrders(response.data);
        setFilteredOrders(response.data);
        
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    let filtered = orders;

    if (filterOrderId) {
      filtered = filtered.filter(order => order.orderID.includes(filterOrderId));
    }

    if (filterProductName) {
      filtered = filtered.filter(order =>
        order.items.some(item =>
          item.productId && item.productId.title.toLowerCase().includes(filterProductName.toLowerCase())
        )
      );
    }

    setFilteredOrders(filtered);
  }, [filterOrderId, filterProductName, orders]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Order List</h1>

      <div className="filters">
        <label>
          Filter by Order ID:
          <input
            type="text"
            value={filterOrderId}
            onChange={e => setFilterOrderId(e.target.value)}
          />
        </label>
        <label>
          Filter by Product Name:
          <input
            type="text"
            value={filterProductName}
            onChange={e => setFilterProductName(e.target.value)}
          />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>Items</th>
            <th>Total Price</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Order ID</th>
            <th>User ID</th>
            <th>Delivery Address</th>
            <th>Payment Status</th>
            <th>Payment Mode</th>
           
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map(order => (
            <tr key={order._id}>
              <td>
                <table className="sub-table">
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map(item => (
                      <tr key={item._id}>
                        <td>{item.productId?._id || 'N/A'}</td>
                        <td>{item.productId?.title || 'N/A'}</td>
                        <td>{item.quantity}</td>
                        <td>₹{item.totalPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td>₹{order.totalPrice}</td>
              <td>{order.status}</td>
              <td>
                {new Date(order.createdAt).toLocaleDateString()}<br />
                {new Date(order.createdAt).toLocaleTimeString()}
              </td>
              <td>
                {new Date(order.updatedAt).toLocaleDateString()}<br />
                {new Date(order.updatedAt).toLocaleTimeString()}
              </td>
              <td>{order.orderID}</td>
              <td>{order.userId}</td>
              <td>
                {order.address ? (
                  <>
                    <div>Name: {order.address.name || 'N/A'}</div>
                    <div>Address: {order.address.location || 'N/A'}</div>
                    <div>{order.address.city || 'N/A'}</div>
                    <div>{order.address.state || 'N/A'}</div>
                    <div>{order.address.pincode || 'N/A'}</div>
                    <div>Phone: {order.address.phone || 'N/A'}</div>
                    <div>Email: {order.address.email || 'N/A'}</div>
                    <div>Delivery Days: {order.address.deliveryDays || 'N/A'}</div>
                  </>
                ) : (
                  'Address information is not available'
                )}
              </td>
              <td>{order.paymentStatus}</td>
              <td>{order.paymentMode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
