import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import QRCode from "react-qr-code";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactPaginate from "react-paginate";
import apiConfig from "../../../apiConfig";
import "../page.css";
import { useNavigate } from "react-router-dom";
import DropdownOfStatus from "./DropdownOfStatus";
import BackButton from "./BackButton";
import ReactDOM from "react-dom";
import "jspdf-autotable";
import logoImage from "../../../assets/images/icons/shippingLogo.png"; // Adjust the path based on your project structure

const Accepted = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [ordersPerPage] = useState(10);
  const tableRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [bankDetails, setBankDetails] = useState({}); // New state for bank details
  const [upiId, setUpiId] = useState("");
  const UpiId = localStorage.getItem("UpiId");
  // console.log("UpiId",upiId)

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.Accepted}`
      );
      setOrders(response.data);
      setFilteredOrders(response.data);
      console.log("neetu", response.data);
      setLoading(false);
    } catch (err) {
      setError("");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    let filtered = orders;

    if (filterOrderId) {
      filtered = filtered.filter((order) =>
        order.orderID.includes(filterOrderId)
      );
    }

    if (filterProductName) {
      filtered = filtered.filter((order) =>
        order.items.some(
          (item) =>
            item.productId &&
            item.productId.title
              .toLowerCase()
              .includes(filterProductName.toLowerCase())
        )
      );
    }

    setFilteredOrders(filtered);
  }, [filterOrderId, filterProductName, orders]);

  const downloadPDF = async () => {
    const input = tableRef.current;
    if (!input) return;

    const canvas = await html2canvas(input, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("order_list.pdf");
  };

  const handleRefresh = async () => {
    await fetchOrders();
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleNavigate = () => {
    navigate("/admin/OrderTracking");
  };

  const handleStatusChange = async (orderID) => {
    setLoading(true);
    try {
      const nextStatus = getNextStatus(selectedStatus);

      if (nextStatus === newStatus) {
        await axios.put(
          `${apiConfig.baseUrl}/admin/order/${orderID}/updateStatus`,
          { status: newStatus }
        );

        await fetchOrders();
        setModalMessage("Order status updated successfully.");
        alert("status updated successfully")
        // setShowModal(true);
      } else {
        console.error("Cannot update to the selected status:", newStatus);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "received":
        return "blue";
      case "processing":
        return "#EF9B0F";
      case "shipped":
        return "green";
      case "delivered":
        return "purple";
      case "cancelled":
        return "#C40233";
      default:
        return "black";
    }
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "received":
        return "processing";
      case "processing":
        return "shipped";
      case "shipped":
        return "delivered";
      case "delivered":
        return "";
      case "cancelled":
        return "";
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchUpiId = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetBankAllDetails}`
        );
        const bankData = response.data.data;
        if (bankData.length > 0) {
          setUpiId(bankData[0].UpiId);
          setBankDetails(bankData[0]); // Set the bank details
          // console.log("detaiks", bankData[0]);
          // console.log("bank details", response.data);
        }
      } catch (err) {
        console.error("Error fetching bank details:", err);
      }
    };

    fetchUpiId();
  }, []);

  const offset = currentPage * ordersPerPage;
  const currentOrders = filteredOrders.slice(offset, offset + ordersPerPage);
  const pageCount = Math.ceil(filteredOrders.length / ordersPerPage);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // const downloadShippingLabel = async (order, bankData = {}) => {
  //   // console.log("bankData", bankData);
  //   try {
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pageWidth = pdf.internal.pageSize.width; // Page width
  //     const pageHeight = pdf.internal.pageSize.height; // Page height

  //     // Load image as a Promise
  //     const loadImage = (src) => {
  //       return new Promise((resolve, reject) => {
  //         const img = new Image();
  //         img.src = src;
  //         img.onload = () => resolve(img);
  //         img.onerror = (err) => reject(err);
  //       });
  //     };

  //     // Add logo image to the PDF with updated size and position
  //     const img = await loadImage(logoImage); // Ensure `logoImage` is defined with a valid path
  //     const logoWidth = 50; // Adjusted logo width
  //     const logoHeight = 20; // Adjusted logo height for larger size
  //     const logoX = (pageWidth - logoWidth) / 2; // Center the logo
  //     const logoY = 5; // Move the logo closer to the top
  //     pdf.addImage(img, "PNG", logoX, logoY, logoWidth, logoHeight);

  //     // Dynamic positioning for Order ID, Date, and Time Header
  //     let currentYPosition = logoY + logoHeight + 5; // Set position after logo
  //     const addContent = (text, yOffset) => {
  //       if (currentYPosition + yOffset > pageHeight - 20) {
  //         // Check if space is left
  //         pdf.addPage();
  //         currentYPosition = 20; // Reset position for new page
  //       }
  //       pdf.text(text, 10, currentYPosition);
  //       currentYPosition += yOffset;
  //     };

  //     const createdAtDate = new Date(order.createdAt).toLocaleDateString(
  //       "en-GB",
  //       {
  //         day: "2-digit",
  //         month: "2-digit",
  //         year: "numeric",
  //       }
  //     );
  //     const createdAtTime = new Date(order.createdAt).toLocaleTimeString(
  //       "en-US",
  //       {
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         hour12: true,
  //       }
  //     );

  //     addContent(`Order ID: ${order.orderID}`, 10);
  //     addContent(`Date: ${createdAtDate}`, 10);
  //     addContent(`Time: ${createdAtTime}`, 10);

  //     // Divider line
  //     pdf.setDrawColor(100, 100, 100);
  //     pdf.setLineWidth(0.5);
  //     pdf.line(
  //       10,
  //       currentYPosition + 10,
  //       pageWidth - 10,
  //       currentYPosition + 10
  //     );
  //     currentYPosition += 20; // Move below the line

  //     // Product Information Table
  //     let initialTotalPrice = 0;
  //     let totalShippingCost = order.shippingCost || 0;
  //     const couponDiscount = order.items[0].couponDiscount || 0;
  //     const productRows = order.items.map((item, index) => {
  //       initialTotalPrice += item.totalPrice || 0;
  //       return [
  //         index + 1,
  //         item.productId || "N/A",
  //         item.title || "N/A",
  //         item.quantity || "N/A",
  //         item.totalPrice || "N/A",
  //       ];
  //     });
  //     const finalTotalPrice = order.totalPrice + order.shippingCost;

  //     // Add dynamic Product Information
  //     pdf.setFont("helvetica", "bold");
  //     pdf.text("Product Information", 10, currentYPosition);
  //     pdf.autoTable({
  //       startY: currentYPosition + 10,
  //       head: [["#", "Product ID", "Title", "Quantity", "Total Price"]],
  //       body: productRows,
  //       theme: "grid",
  //       styles: { fontSize: 10 },
  //       headStyles: {
  //         fillColor: [240, 240, 240],
  //         fontStyle: "bold",
  //         textColor: 0,
  //       },
  //     });

  //     // Update Y position after table
  //     currentYPosition = pdf.autoTable.previous.finalY + 10;

  //     // Price Details Table
  //     pdf.autoTable({
  //       startY: currentYPosition,
  //       head: [["Description", "Amount"]],
  //       body: [
  //         ["Initial Total Price", `${initialTotalPrice.toFixed(2)}`],
  //         ["Shipping Cost", `${totalShippingCost.toFixed(2)}`],
  //         ["Coupon Discount", `${couponDiscount.toFixed(2)}`],
  //         [
  //           "Final Total Price (Including Shipping)",
  //           `${finalTotalPrice.toFixed(2)}`,
  //         ],
  //       ],
  //       theme: "grid",
  //       styles: { fontSize: 12 },
  //       headStyles: {
  //         fillColor: [240, 240, 240],
  //         fontStyle: "bold",
  //         textColor: 0,
  //       },
  //     });

  //     // Update Y position after table
  //     currentYPosition = pdf.autoTable.previous.finalY + 10;

  //     // Shipping Information
  //     pdf.text("Shipping Information", 10, currentYPosition);
  //     pdf.autoTable({
  //       startY: currentYPosition + 10,
  //       head: [["Shipping Information", "Details"]],
  //       body: [
  //         ["Name", order.address?.name || "N/A"],
  //         ["Address", order.address?.location || "N/A"],
  //         ["City", order.address?.city || "N/A"],
  //         ["State", order.address?.state || "N/A"],
  //         ["Pincode", order.address?.pincode || "N/A"],
  //         ["Phone", order.address?.phone || "N/A"],
  //         ["Email", order.address?.email || "N/A"],
  //         ["Delivery Days", order.address?.deliveryDays || "N/A"],
  //       ],
  //       theme: "grid",
  //       styles: { fontSize: 10 },
  //       headStyles: {
  //         fillColor: [240, 240, 240],
  //         fontStyle: "bold",
  //         textColor: 0,
  //       },
  //     });

  //     // Update Y position after table
  //     currentYPosition = pdf.autoTable.previous.finalY + 10;

  //     // Bank details section
  //     pdf.text("Bank Details", 10, currentYPosition);
  //     pdf.autoTable({
  //       startY: currentYPosition + 10,
  //       head: [["Bank Details", "Information"]],
  //       body: [
  //         ["Bank Name", bankDetails?.BankName || "N/A"],
  //         ["IFSC Code", bankDetails?.IfscCode || "N/A"],
  //         ["Account Number", bankDetails?.AccountNumber || "N/A"],
  //         ["Account Holder Name", bankDetails?.AccountHolderName || "N/A"],
  //         ["UPI ID", bankDetails?.UpiId || "N/A"],
  //       ],
  //       theme: "grid",
  //       styles: { fontSize: 10 },
  //       headStyles: {
  //         fillColor: [240, 240, 240],
  //         fontStyle: "bold",
  //         textColor: 0,
  //       },
  //     });

  //     // Update Y position after table
  //     currentYPosition = pdf.autoTable.previous.finalY + 10;

  //     // Calculate Delivery Date based on Delivery Days
  //     let deliveryDays = parseInt(order.address?.deliveryDays) || 0;
  //     if (isNaN(deliveryDays)) {
  //       deliveryDays = 0;
  //     }
  //     const createdAt = new Date(order.createdAt);
  //     createdAt.setDate(createdAt.getDate() + deliveryDays);
  //     const deliveryDate = createdAt.toLocaleDateString("en-GB", {
  //       day: "2-digit",
  //       month: "2-digit",
  //       year: "numeric",
  //     });

  //     // Delivery Date
  //     addContent(`Estimated Delivery Date: ${deliveryDate}`, 10);

  //     // Add QR code if needed
  //     const currentPageHeight = pdf.internal.pageSize.height;
  //     const currentYPositionAfterContent = currentYPosition + 20; // Position after content

  //     // Dynamically handle QR code based on space
  //     if (currentYPositionAfterContent > currentPageHeight - 50) {
  //       pdf.addPage(); // Add new page if content overflows
  //       currentYPosition = 20; // Reset Y position after page break
  //     }

  //     // Ensure bankData and finalTotalPrice are valid before generating QR code
  //     const finalAmount = finalTotalPrice.toFixed(2); // Ensure price is a number with two decimals
  //     const upiId = bankData?.upiId || "default-upi-id"; // Ensure upiId exists in bankData

  //     const qrCodeURL = `upi://pay?pa=${bankDetails?.UpiId}&am=${finalAmount}&cu=INR`;
  //     console.log("QR Code URL:", qrCodeURL); // Log for debugging

  //     // Generate the QR code
  //     const qrCodeCanvas = document.createElement("canvas");
  //     await QRCode.toCanvas(qrCodeCanvas, qrCodeURL);
  //     const qrCodeImage = qrCodeCanvas.toDataURL("image/png");

  //     // Dynamically adjust QR code position based on available space
  //     const qrCodeHeight = 50; // Set a fixed size for the QR code
  //     const qrCodeWidth = 50; // Set a fixed size for the QR code

  //     // Calculate QR position
  //     const qrCodeX = pageWidth - qrCodeWidth - 10; // 10 mm from right
  //     const qrCodeY = currentYPosition + 20; // 20 mm after last content

  //     // Add the QR code image
  //     pdf.addImage(
  //       qrCodeImage,
  //       "PNG",
  //       qrCodeX,
  //       qrCodeY,
  //       qrCodeWidth,
  //       qrCodeHeight
  //     );
  //     pdf.setFontSize(13);
  //     pdf.text("Scan this QR for payment", qrCodeX, qrCodeY + qrCodeHeight + 5); // Adjust text position

  //     // Save the PDF
  //     pdf.save(`Shipping_Label_${order.orderID}.pdf`);
  //   } catch (err) {
  //     console.error("Error downloading shipping label:", err);
  //   }
  // };

  const downloadShippingLabel = async (order, bankData = {}) => {
    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.width; // Page width
      const pageHeight = pdf.internal.pageSize.height; // Page height

      // Load image as a Promise
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
        });
      };

      // Add logo image to the PDF
      const img = await loadImage(logoImage); // Ensure `logoImage` is defined with a valid path
      const logoWidth = 50; // Adjusted logo width
      const logoHeight = 20; // Adjusted logo height
      const logoX = pageWidth - logoWidth - 10; // Position logo on the right side
      const logoY = 5; // Position logo closer to the top
      pdf.addImage(img, "PNG", logoX, logoY, logoWidth, logoHeight);

      // Dynamic positioning for Order ID and Date on the left side
      const createdAtDate = new Date(order.createdAt).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      // Add Order ID and Date to the left side
      const orderInfoX = 10; // Position on the left side
      let currentYPosition = logoY + 5; // Set vertical position right below the logo

      pdf.setFont("helvetica", "bold");
      pdf.text(`Order ID: ${order.orderID}`, orderInfoX, currentYPosition);
      currentYPosition += 7;
      pdf.text(`Date: ${createdAtDate}`, orderInfoX, currentYPosition);

      // Divider line below the header
      pdf.setDrawColor(100, 100, 100);
      pdf.setLineWidth(0.5);
      pdf.line(8, currentYPosition + 10, pageWidth - 10, currentYPosition + 10);
      currentYPosition += 20; // Move below the line

      // Adjust the address Y position to make it higher
      const addressX = 10; // Left position for address
      let addressY = currentYPosition - 3; // Move address section 10 units higher

      // Define coordinates for the "Ship To" and "Ship By" sections

      const shipByX = addressX + 163; // X position for "Ship By", move it a little to the right

      // Set the font for "Ship To"
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.text("Ship To", addressX, addressY); // Place "Ship To" on the left side

      // Move Y position down after "Ship To"
      addressY += 5;

      // Add the address details (Name, Location, etc.) below "Ship To"
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(11);

      // Add Name field
      pdf.text(`Name: ${order.address?.name || "N/A"}`, addressX, addressY);
      addressY += 6; // Space between address fields

      // Combine location, city, and state in one line
      const locationCityState = `${order.address?.location || "N/A"}, ${
        order.address?.city || "N/A"
      }, ${order.address?.state || "N/A"}, ${order.address?.pincode || "N/A"}`;
      pdf.text(`Address: ${locationCityState}`, addressX, addressY);
      addressY += 6;

      // Add Phone number
      pdf.text(`Phone: ${order.address?.phone || "N/A"}`, addressX, addressY);
      addressY += 6;

      // Draw line after the address
      pdf.setDrawColor(0, 0, 0); // Set line color to black
      pdf.setLineWidth(0.5); // Set line width
      pdf.line(10, addressY, pageWidth - 10, addressY); // Draw the line after address section

      // Add "Ship By" section next to "Ship To"
      pdf.setFont("helvetica", "bold"); // Set font to bold for "Ship By"
      pdf.setFontSize(12);
      let shipByY = currentYPosition + 3; // Adjust Y position for "Ship By"
      pdf.text("Ship By", shipByX, shipByY); // Place "Ship By" to the right of "Ship To"
      shipByY += 8; // Add space after "Ship By" text

      // Add "Bharat Cod" below "Ship By"
      pdf.setFont("helvetica", "normal"); // Set font to normal for "Bharat Cod"
      pdf.text("Bharat Cod", shipByX, shipByY);
      shipByY += 10; // Add space after "Bharat Cod" before the next section


      // Update Y position after "Ship By" section
      currentYPosition = shipByY + 5;

      // Product Information Table
      let initialTotalPrice = 0;
      let totalShippingCost = order.shippingCost || 0;
      // const couponDiscount = order.items[0].couponDiscount || 0;
      const couponDiscount = order.items.reduce((total, item) => {
        return total + (item.couponDiscount || 0); // Add each item's coupon discount, defaulting to 0 if not available
      }, 0);

      const productRows = order.items.map((item, index) => {
        const unitPrice = item.price || 0; // Correct field for unit price
        const quantity = item.quantity || 0; // Correct field for quantity
        const totalPrice = unitPrice * quantity; // Total price = Unit Price * Quantity

        initialTotalPrice += totalPrice; // Update initial total price with calculated total price

        return [
          item.title || "N/A",
          quantity || "N/A",
          unitPrice.toFixed(2) || "N/A", // Showing the unit price with two decimal places
          item.taxablevalue || "0",
          item.cgst || "0",
          item.sgst || "0",
          totalPrice.toFixed(2) || "", // Showing the total price with two decimal places
        ];
      });

      const finalTotalPrice = order.totalPrice + order.shippingCost;

      // Add dynamic Product Information
      pdf.setFont("helvetica", "bold");

      pdf.autoTable({
        startY: currentYPosition,
        head: [
          [
            "Title",
            "Qty",
            "Unit Price",
            "Taxable Value",
            "CGST",
            "SGST",
            "Total Price",
          ],
        ],
        body: productRows,
        theme: "grid",
        styles: { fontSize: 10 },
        headStyles: {
          fillColor: [240, 240, 240],
          fontStyle: "bold",
          textColor: 0,
        },
      });

      // Update Y position after table
      currentYPosition = pdf.autoTable.previous.finalY + 10;

      // Price Details Table
      pdf.autoTable({
        startY: currentYPosition,
        head: [["Description", "Amount"]],
        body: [
          ["Initial Total Price", `${initialTotalPrice.toFixed(2)}`],
          ["Shipping Cost", `${totalShippingCost.toFixed(2)}`],
          ["Coupon Discount", `${couponDiscount.toFixed(2)}`],
          [
            "Final Total Price (Including Shipping)",
            `${finalTotalPrice.toFixed(2)}`,
          ],
        ],
        theme: "grid",
        styles: { fontSize: 12 },
        headStyles: {
          fillColor: [240, 240, 240],
          fontStyle: "bold",
          textColor: 0,
        },
      });

      // Update Y position after table
      currentYPosition = pdf.autoTable.previous.finalY + 10;

      // Bank details section on the left side as simple text
      const bankDetailsX = 10;
      const bankDetailsY = currentYPosition;

      // Add Bank Details in simple UI format (left aligned)
      pdf.setFont("helvetica", "normal"); // Set normal font for the bank details
      pdf.setFontSize(10);

      pdf.text(
        `Bank Name: ${bankDetails?.BankName || "N/A"}`,
        bankDetailsX,
        currentYPosition
      );
      currentYPosition += 6;
      pdf.text(
        `IFSC Code: ${bankDetails?.IfscCode || "N/A"}`,
        bankDetailsX,
        currentYPosition
      );
      currentYPosition += 6;
      pdf.text(
        `Account Number: ${bankDetails?.AccountNumber || "N/A"}`,
        bankDetailsX,
        currentYPosition
      );
      currentYPosition += 6;
      pdf.text(
        `Account Holder Name: ${bankDetails?.AccountHolderName || "N/A"}`,
        bankDetailsX,
        currentYPosition
      );
      currentYPosition += 6;
      pdf.text(
        `UPI ID: ${bankDetails?.UpiId || "N/A"}`,
        bankDetailsX,
        currentYPosition
      );

      // QR Code on the right side
      const qrCodeURL = `upi://pay?pa=${
        bankDetails?.UpiId
      }&am=${finalTotalPrice.toFixed(2)}&cu=INR`;
      const qrCodeCanvas = document.createElement("canvas");
      await QRCode.toCanvas(qrCodeCanvas, qrCodeURL);
      const qrCodeImage = qrCodeCanvas.toDataURL("image/png");

      const qrCodeWidth = 30; // Set a fixed size for the QR code
      const qrCodeHeight = 30; // Set a fixed size for the QR code
      const qrCodeX = pageWidth - qrCodeWidth - 10; // Position QR code on the right
      const qrCodeY = bankDetailsY - 5; // Place it at the same height as the bank details

      // Add QR code to the PDF
      pdf.addImage(
        qrCodeImage,
        "PNG",
        qrCodeX,
        qrCodeY,
        qrCodeWidth,
        qrCodeHeight
      );

      // Save the PDF file with the orderID
      pdf.save(`Shipping_Label_${order.orderID}.pdf`);
    } catch (error) {
      console.error("Error generating the shipping label:", error);
    }
  };

  return (
    <div>
      <BackButton />
      <div className="filters">
        <label>
          Filter by Order ID:
          <input
            type="text"
            value={filterOrderId}
            onChange={(e) => setFilterOrderId(e.target.value)}
          />
        </label>
        {/* <label>
          Filter by Product Name:
          <input
            type="text"
            value={filterProductName}
            onChange={(e) => setFilterProductName(e.target.value)}
          />
        </label> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "20px",
          }}
        >
          <DropdownOfStatus />
        </div>
        <div>
          <button
            onClick={handleNavigate}
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          >
            Order Track and Update
          </button>
        </div>
        <div style={{ textAlign: "right" }}>
          {/* <button
            onClick={downloadPDF}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#007BFF",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#0056b3")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#007BFF")
            }
          >
            Download Full List of orders as PDF
          </button> */}
          <button
            onClick={handleRefresh}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#28A745",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#218838")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#28A745")
            }
          >
            Refresh Data
          </button>
        </div>
      </div>
      <div ref={tableRef}>
        <h1> List Of Accepted Order</h1>
        <table>
          <thead>
            <tr>
              <th>Items</th>

              <th> Product Price</th>
              <th>Shipping Price</th>
              <th>Coupon Discount</th>

              <th>Total Amount</th>
              <th>Order date</th>
              <th>Order ID</th>
              <th>Delivery Address</th>
              <th>Payment Status</th>
              <th>Payment Mode</th>

              <th>Status</th>
              <th>User ID</th>
              <th>Actions Change Product status</th>
              <th>Shipping label download</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders.map((order) => (
              <tr key={order._id}>
                <td>
                  <table className="sub-table">
                    <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th> Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.items.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>
                              {item?.variation?.variationId
                                ? item?.variation?.variationId
                                : item.productId || "N/A"}
                            </td>
                            <td>
                              {item?.variation?.variationTitle
                                ? item?.variation?.variationTitle
                                : item.title || "N/A"}
                            </td>
                            <td>{item.quantity}</td>
                            <td>₹{item.totalPrice}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
                {/* <td>₹{order.shippingCost ?? 0}</td> */}
                {/* <td>₹{order.totalPrice ?? 0}</td>
                <td>₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}</td> */}
                {order.items.map((item, index) => {
                  const productPrice = item.totalPrice || 0;
                  const variationPrice = item.variation?.VariationPrice || 0;

                  let totalPrice = productPrice;
                  if (item.productId && item.variation) {
                    totalPrice += variationPrice;
                  }

                  return (
                    <tr key={index}>
                      <td>₹{item.totalPrice}</td>
                    </tr>
                  );
                })}

                <td>₹{order.shippingCost ?? 0}</td>

                {order.items.map((item, index) => (
                  <tr key={index}>
                    <td>₹{item.couponDiscount}</td>
                  </tr>
                ))}

                <td>₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}</td>

                <td>
                  {new Date(order.createdAt).toLocaleDateString()}
                  <br />
                  {new Date(order.createdAt).toLocaleTimeString()}
                </td>
                <td>{order.orderID}</td>

                <td>
                  {order.address ? (
                    <>
                      <div>Name: {order.address.name || "N/A"}</div>
                      <div>Address: {order.address.location || "N/A"}</div>
                      <div>{order.address.city || "N/A"}</div>
                      <div>{order.address.state || "N/A"}</div>
                      <div>{order.address.pincode || "N/A"}</div>
                      <div>Phone: {order.address.phone || "N/A"}</div>
                      <div>Email: {order.address.email || "N/A"}</div>
                      <div>
                        Delivery Days: {order.address.deliveryDays || "N/A"}
                      </div>
                    </>
                  ) : (
                    "Address information is not available"
                  )}
                </td>
                <td>{order.paymentStatus}</td>
                <td>{order.paymentMode}</td>
                {/* <td>
                  <QRCode
                    value={`upi://pay?pa=${UpiId}&pn=BharatCod&am=${
                      (order.totalPrice ?? 0) + (order.shippingCost ?? 0)
                    }&cu=INR&tr=${
                      order.orderID
                    }&tn=Order%20ID%3A%20${encodeURIComponent(order.orderID)}`}
                    size={100}
                  />
                 
                </td> */}
                <td>{order.status}</td>
                <td>{order.userId}</td>
                <td>
                  {order.status !== "delivered" && (
                    <>
                      <select
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value={getNextStatus(order.status)}>
                          {getNextStatus(order.status)}
                        </option>
                      </select>
                      <button
                        style={{
                          padding: "1px 2px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: "green",
                          border: "none",
                          borderRadius: "5px",
                          cursor: loading ? "not-allowed" : "pointer",
                          margin: "5px",
                          opacity: loading ? 0.6 : 1,
                          transition: "background-color 0.3s ease",
                        }}
                        onClick={() => {
                          setSelectedStatus(order.status);
                          handleStatusChange(order.orderID);
                        }}
                      >
                        Update Status
                      </button>
                    </>
                  )}
                </td>
                <td>
                  <button
                    onClick={() => downloadShippingLabel(order)}
                    style={{
                      padding: "8px 16px", // Reduced padding for smaller button size
                      border: "none",
                      borderRadius: "6px", // Slightly smaller border radius
                      background: "linear-gradient(90deg, #28A745, #34D058)",
                      color: "#ffffff",
                      fontSize: "14px", // Smaller font size
                      fontWeight: "bold",
                      cursor: "pointer",
                      margin: "8px", // Reduced margin for less spacing
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",
                      transition: "transform 0.2s ease, box-shadow 0.2s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "scale(1.03)";
                      e.target.style.boxShadow =
                        "0px 5px 10px rgba(0, 0, 0, 0.25)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "scale(1)";
                      e.target.style.boxShadow =
                        "0px 3px 6px rgba(0, 0, 0, 0.15)";
                    }}
                  >
                    Download PDF
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
      {showModal && (
        <div className="modal ">
          <p style={{ color: "green" }}>{modalMessage}</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Accepted;
