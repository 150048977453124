import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const AddBankDetails = () => {
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [holderName, setAccountHolder] = useState("");
  const [upiId, setUpiId] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);

  const fetchBankDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GetBankAllDetails}`
      );
      setBankDetails(response.data.data);
      resetForm();
    } catch (error) {
      console.error("Error fetching bank details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setBankName("");
    setIfscCode("");
    setAccountHolder("");
    setUpiId("");
    setAccountNumber("");
  };

  const addBankDetails = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${apiConfig.baseUrl}${apiConfig.AddBankDetails}`, {
        BankName: bankName,
        IfscCode: ifscCode,
        AccountNumber: accountNumber,
        AccountHolderName: holderName,
        UpiId: upiId,
      });
      alert("Bank details added successfully!");
      fetchBankDetails();
    } catch (error) {
      console.error("Error adding bank details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateBankDetails = async () => {
    if (!selectedBankId) {
      alert("Please select a bank to update");
      return;
    }
    try {
      setIsLoading(true);
      await axios.put(
        `${apiConfig.baseUrl}${apiConfig.BankDetailsUpdate}/${selectedBankId}`,
        {
          BankName: bankName,
          IfscCode: ifscCode,
          AccountNumber: accountNumber,
          AccountHolderName: holderName,
          UpiId: upiId,
        }
      );
      alert("Bank details updated successfully");
      fetchBankDetails();
    } catch (error) {
      console.error("Error updating bank details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.DeleteBankAccount}/${id}`
      );
      alert(res?.data?.message);
      fetchBankDetails();
    } catch (err) {
      alert("Error", err.message || "An unexpected error occurred");
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const buttonStyle = {
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#CCCCCC",
    cursor: "not-allowed",
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "700px",
        margin: "auto",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      }}
    >
      <h2 style={{ textAlign: "center", color: "#333" }}>
        Manage Bank Account Details
      </h2>
      <div style={{ marginBottom: "10px" }}>
        <label
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          Bank Name:
          <input
            type="text"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            style={{
              marginLeft: "10px",
              padding: "5px",
              flex: "1",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          IFSC Code:
          <input
            type="text"
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
            style={{
              marginLeft: "10px",
              padding: "5px",
              flex: "1",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          Account Number:
          <input
            type="number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            style={{
              marginLeft: "10px",
              padding: "5px",
              flex: "1",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          Account Holder Name:
          <input
            type="text"
            value={holderName}
            onChange={(e) => setAccountHolder(e.target.value)}
            style={{
              marginLeft: "10px",
              padding: "5px",
              flex: "1",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          UPI ID:
          <input
            type="text"
            value={upiId}
            onChange={(e) => setUpiId(e.target.value)}
            style={{
              marginLeft: "10px",
              padding: "5px",
              flex: "1",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </label>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          justifyContent: "center",
        }}
      >
        <button
          onClick={addBankDetails}
          disabled={isLoading}
          style={isLoading ? disabledButtonStyle : buttonStyle}
        >
          Add Bank Details
        </button>
        <button
          onClick={updateBankDetails}
          disabled={isLoading || !selectedBankId}
          style={
            isLoading || !selectedBankId ? disabledButtonStyle : buttonStyle
          }
        >
          Update Bank Details
        </button>
        <button
          onClick={fetchBankDetails}
          disabled={isLoading}
          style={isLoading ? disabledButtonStyle : buttonStyle}
        >
          Refresh
        </button>
      </div>
      <div>
        <h3 style={{ color: "#333" }}>Bank Details:</h3>
        {bankDetails.length > 0 ? (
          bankDetails.map((bank) => (
            <div
              key={bank._id}
              style={{
                marginBottom: "10px",
                borderBottom: "1px solid #CCC",
                padding: "10px",
                backgroundColor: "#fff",
                borderRadius: "4px",
              }}
            >
              <p>
                <strong>Bank Name:</strong> {bank.BankName}
              </p>
              <p>
                <strong>IFSC Code:</strong> {bank.IfscCode}
              </p>
              <p>
                <strong>Account Number:</strong> {bank.AccountNumber}
              </p>
              <p>
                <strong>Account Holder Name:</strong> {bank.AccountHolderName}
              </p>
              <p>
                <strong>UPI ID:</strong> {bank.UpiId}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  style={{ ...buttonStyle, backgroundColor: "#28a745" }}
                  onClick={() => {
                    setSelectedBankId(bank._id);
                    setBankName(bank.BankName || "");
                    setIfscCode(bank.IfscCode || "");
                    setAccountNumber(bank.AccountNumber || "");
                    setAccountHolder(bank.AccountHolderName || "");
                    setUpiId(bank.UpiId || "");
                  }}
                >
                  Edit
                </button>
                <button
                  style={{ ...buttonStyle, backgroundColor: "red" }}
                  onClick={() => handleDelete(bank._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No bank details available.</p>
        )}
      </div>
    </div>
  );
};

export default AddBankDetails;
