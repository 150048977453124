import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const ReferralFeature = () => {
  const [parentIds, setParentIds] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [specifications, setSpecifications] = useState([]);
  const [specificationEntries, setSpecificationEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    regularPrice: "",
    category: "",
    color: "",
    size: [],
    stock: "",
    // specifications: [{ key: "", values: [] }],
    productimage1: null,
    productimage2: null,
    productimage3: null,
  });
  // console.log("Specification Entries Before Submission:", specificationEntries);

  useEffect(() => {
    const fetchParentIds = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setParentIds(response.data);
        // console.log("category: ", response.data);
        if (response.data.length > 0) {
          setSelectedParentId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching parent IDs:", error);
      }
    };

    fetchParentIds();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedParentId) {
          const response = await axios.get(
            `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
          );
          setSubcategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [selectedParentId]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(response.data);
        // console.log(response);
        if (response.data.length > 0) {
          setSelectedBrand("");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
        );
        if (response.data && Array.isArray(response.data.data)) {
          setSpecifications(response.data.data.map((spec) => spec.key));
          // console.log("Specifications", response.data.data);
        } else {
          console.error(
            "Specifications data is not an array or is missing:",
            response.data
          );
          setSpecifications([]);
        }
      } catch (error) {
        console.error("Error fetching specifications:", error);
        setSpecifications([]);
      }
    };

    fetchSpecifications();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      const selectedCategory = parentIds.find(
        (category) => category.name === value
      );
      setSelectedParentId(selectedCategory ? selectedCategory._id : "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSizeChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      size: e.target.value.split(",").map((item) => item.trim()),
    }));
  };

  const handleAddSpecification = () => {
    setSpecificationEntries((prevEntries) => [
      ...prevEntries,
      { key: "", values: [""] },
    ]);
  };

  const handleRemoveSpecification = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.filter((_, i) => i !== index)
    );
  };

  // const handleSpecificationChange = (index, event) => {
  //   const { value } = event.target;
  //   setSpecificationEntries((prevEntries) =>
  //     prevEntries.map((entry, i) =>
  //       i === index ? { ...entry, key: value } : entry
  //     )
  //   );
  // };

  const handleSpecificationChange = (index, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, key: value } : entry
      )
    );
  };

  const handleValueChange = (specIndex, valueIndex, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.map((val, j) =>
                j === valueIndex ? value : val
              ),
            }
          : entry
      )
    );
  };

  const handleAddValue = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, values: [...entry.values, ""] } : entry
      )
    );
  };

  const handleRemoveValue = (specIndex, valueIndex) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.filter((_, j) => j !== valueIndex),
            }
          : entry
      )
    );
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    console.log(formDataToSend);
    for (const [key, value] of Object.entries(formData)) {
      if (Array.isArray(value)) {
        formDataToSend.append(key, JSON.stringify(value));
      } else if (value) {
        formDataToSend.append(key, value);
      }
    }
    // console.log(specificationEntries);

    formDataToSend.append("brand", selectedBrand);
    formDataToSend.append("subcategory", selectedSubcategory);

    formDataToSend.append(
      "specifications",
      JSON.stringify(specificationEntries)
    );

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.ReferralFeature}`,
        formDataToSend,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert("Referral added successfully");
      console.log(response.data);
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product");
    }
  };

  return (
    <div>
      <h1
        style={{
          textAlign: "center",
          margin: "20px 0",
          color: "#333",
          fontFamily: "Arial, sans-serif",
        }}
      >
        Insert Gift Product
      </h1>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "600px",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Description
          </label>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Regular Price
          </label>
          <input
            type="number"
            name="regularPrice"
            value={formData.regularPrice}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            className="form-control"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {parentIds.map((parent) => (
              <option key={parent._id} value={parent.name}>
                {parent.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            name="subcategory"
            className="form-control"
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory.name} value={subcategory.name}>
                {subcategory.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="brand">Brand</label>
          <select
            id="brand"
            name="brand"
            className="form-control"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="selectedBrand">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand._id} value={brand.name}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Color</label>
          <input
            type="text"
            name="color"
            value={formData.color}
            onChange={handleChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Size</label>
          <input
            type="text"
            name="size"
            value={formData.size.join(",")}
            onChange={handleSizeChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
          <small style={{ color: "#666" }}>
            Use commas to separate sizes (e.g., S, M, L)
          </small>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Stock</label>
          <input
            type="number"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>
        <div className="form-group mb-3">
          <div
            style={{
              fontSize: "28px",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            Specification
          </div>

          <label htmlFor="specifications"></label>
          <div className="d-flex align-items-center mb-2">
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleAddSpecification}
            >
              Add Specification
            </button>
          </div>
          {specificationEntries?.map((specification, specIndex) => (
            <div key={specIndex} className="mb-2">
              <div className="d-flex mb-2">
                <select
                  className="form-control me-2"
                  value={specification?.key}
                  onChange={(event) =>
                    handleSpecificationChange(specIndex, event)
                  }
                >
                  <option value="">Select Key</option>
                  {specifications.map((specKey) => (
                    <option key={specKey} value={specKey}>
                      {specKey}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveSpecification(specIndex)}
                >
                  Remove Specification
                </button>
              </div>
              {specification?.values.map((value, valueIndex) => (
                <div key={valueIndex} className="d-flex mb-2">
                  <input
                    type="text"
                    placeholder="Value"
                    className="form-control me-2"
                    value={value}
                    onChange={(event) =>
                      handleValueChange(specIndex, valueIndex, event)
                    }
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveValue(specIndex, valueIndex)}
                  >
                    Remove Value
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => handleAddValue(specIndex)}
              >
                Add Value
              </button>
            </div>
          ))}
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Product Image 1
          </label>
          <input
            type="file"
            name="productimage1"
            onChange={handleFileChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
          <label style={{ display: "block", marginBottom: "5px" }}>
            Product Image 2
          </label>
          <input
            type="file"
            name="productimage2"
            onChange={handleFileChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
          <label style={{ display: "block", marginBottom: "5px" }}>
            Product Image 3
          </label>
          <input
            type="file"
            name="productimage3"
            onChange={handleFileChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>

        {/* Repeat for other file inputs */}
        <button
          type="submit"
          disabled={isLoading}
          style={{
            backgroundColor: isLoading ? "#ccc" : "#3498db",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px 15px",
            cursor: isLoading ? "not-allowed" : "pointer",
            width: "100%",
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ReferralFeature;
