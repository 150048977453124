import React, { useState } from "react";
import axios from "axios";
import ViewPromotionalImg from "./ViewPromotionalImg";
import apiConfig from "../../../apiConfig";
import "./AddPromotionalImg.css";

// const AddPromotionalImg = () => {
//   const [loading, setLoading] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [selectedFileNames, setSelectedFileNames] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [uploadResponse, setUploadResponse] = useState(null);
//   const [error, setError] = useState(null);

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files);
//     setSelectedFiles(files);
//     const fileNames = files.map((file) => file.name);
//     setSelectedFileNames(fileNames);
//   };

//   const handleFileUpload = async () => {
//     if (selectedFiles.length === 0) {
//       alert("Please select one or more files.");
//       return;
//     }

//     setLoading(true);

//     try {
//       const promises = selectedFiles.map(async (file) => {
//         const formData = new FormData();
//         formData.append("images", file);

//         const response = await axios.post(
//           `${apiConfig.baseUrl}${apiConfig.UploadPromotionalOfferImg}`,
//           formData,
//           {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           }
//         );
//         return response.data;
//       });

//       // const results = await Promise.all(promises);
//       // console.log('Images uploaded successfully:', results);
//       alert("Images uploaded successfully");

//       // setUploadResponse(results);
//       setModalOpen(true);
//       setError(null);
//     } catch (error) {
//       console.error("Error uploading images:", error);
//       setError("Error uploading images. Please try again.");
//     } finally {
//       setLoading(false);
//       setSelectedFiles([]);
//       setSelectedFileNames([]);
//     }
//   };

//   // const closeModal = () => {
//   //   setModalOpen(false);
//   //   setUploadResponse(null);
//   // };

//   return (
//     <div className="container">
//       <h2>Add Promotional Images</h2>
//       <div className="containertwo">
//         <input
//           type="file"
//           id="fileInput"
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//           multiple
//         />

//         <button
//           className="label"
//           onClick={() => document.getElementById("fileInput").click()}
//         >
//           Choose Files
//         </button>

//         {/* Display selected file names */}
//         <div className="selected-files">
//           {selectedFileNames.map((fileName, index) => (
//             <p key={index}>{fileName}</p>
//           ))}
//         </div>

//         <button className="button" onClick={handleFileUpload}>
//           Upload Images
//         </button>

//         {loading && <p className="loading-message">Loading...</p>}
//         {error && <p className="error-message">{error}</p>}

//         {/* {modalOpen && (
//           <div className="modal">
//             <div className="modal-content">
//               <span className="close" onClick={closeModal}>
//                 &times;
//               </span>
//               <h3>Upload Response</h3>
//               <pre>
//                 {uploadResponse.map((response, index) => (
//                   <div key={index}>
//                     <p>{response.message}</p>
//                   </div>
//                 ))}
//               </pre>
//             </div>
//           </div>
//         )} */}
//       </div>
//       {/* <ViewPromotionalImg /> */}
//     </div>
//   );
// };

// export default AddPromotionalImg;

// const AddPromotionalImg = () => {
//   const [loading, setLoading] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [selectedFileNames, setSelectedFileNames] = useState([]);
//   const [context, setContext] = useState(""); // State for context
//   const [order, setOrder] = useState(0); // State for order
//   const [uploadResponse, setUploadResponse] = useState(null);
//   const [error, setError] = useState(null);

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files);
//     setSelectedFiles(files);
//     const fileNames = files.map((file) => file.name);
//     setSelectedFileNames(fileNames);
//   };

//   const handleFileUpload = async () => {
//     if (selectedFiles.length === 0) {
//       alert("Please select one or more files.");
//       return;
//     }

//     setLoading(true);

//     try {
//       const promises = selectedFiles.map(async (file) => {
//         const formData = new FormData();
//         formData.append("images", file);
//         formData.append("context", context); // Add context to the request body
//         formData.append("order", order); // Add order to the request body

//         const response = await axios.post(
//           `${apiConfig.baseUrl}${apiConfig.UploadPromotionalOfferImg}`,
//           formData,
//           {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           }
//         );
//         return response.data;
//       });

//       const uploadResponses = await Promise.all(promises);
//       setUploadResponse(uploadResponses); // Store the full response data
//       alert("Images uploaded successfully");
//       setError(null);
//     } catch (error) {
//       console.error("Error uploading images:", error);
//       if (error.response && error.response.data) {
//         setError(error.response.data.message); // Show API-specific error message
//       } else {
//         setError("Error uploading images. Please try again.");
//       }
//     } finally {
//       setLoading(false);
//       setSelectedFiles([]);
//       setSelectedFileNames([]);
//     }
//   };

//   return (
//     <div className="container">
//       <h2>Add Promotional Images</h2>
//       <div className="containertwo">
//         <input
//           type="file"
//           id="fileInput"
//           style={{ display: "none" }}
//           onChange={handleFileChange}
//           multiple
//         />
//         <button
//           className="label"
//           onClick={() => document.getElementById("fileInput").click()}
//         >
//           Choose Files
//         </button>
//         <br />

//         {/* Display selected file names */}
//         <div className="selected-files">
//           {selectedFileNames.map((fileName, index) => (
//             <p key={index}>{fileName}</p>
//           ))}
//         </div>

//         {loading && <p className="loading-message">Loading...</p>}
//         {error && <p className="error-message">{error}</p>}

//         {/* {uploadResponse && (
//           <div className="response-container">
//             <h3>Upload Response</h3>
//             {uploadResponse.map((response, index) => (
//               <div key={index}>

//                 <div>
//                   <strong>Images:</strong>
//                   <ul>

//                     {(response.images || []).map((image, idx) => (
//                       <li key={idx}>
//                         <a href={image} target="_blank" rel="noopener noreferrer">
//                           {image}
//                         </a>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )} */}
//       </div>

//       {/* Input for context */}
//       <div className="context-input-container">
//         <label>Context:</label>
//         <input
//           type="text"
//           value={context}
//           onChange={(e) => setContext(e.target.value)}
//           placeholder="Enter Screen"
//           className="context-input"
//         />
//       </div>

//       {/* Input for order */}
//       <div className="order-input-container">
//         <label>Order:</label>
//         <input
//           type="number"
//           value={order}
//           onChange={(e) => setOrder(e.target.value)}
//           placeholder="Enter Order"
//           className="order-input"
//         />
//       </div>

//       <button className="button" onClick={handleFileUpload}>
//         Upload Images
//       </button>
//     </div>
//   );
// };

// export default AddPromotionalImg;

const AddPromotionalImg = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [context, setContext] = useState(""); // State for context
  const [order, setOrder] = useState(0); // State for order
  const [uploadResponse, setUploadResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const fileNames = files.map((file) => file.name);
    setSelectedFileNames(fileNames);
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select one or more files.");
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();

      // Append all images to formData
      selectedFiles.forEach((file) => {
        formData.append("images", file);
      });

      // Add context and order to formData
      formData.append("context", context);
      formData.append("order", order);

      // Send all files in a single request
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.UploadPromotionalOfferImg}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUploadResponse(response.data); // Store the full response data
      alert("Images uploaded successfully");
      setError(null);
    } catch (error) {
      console.error("Error uploading images:", error);
      if (error.response && error.response.data) {
        setError(error.response.data.message); // Show API-specific error message
      } else {
        setError("Error uploading images. Please try again.");
      }
    } finally {
      setLoading(false);
      setSelectedFiles([]);
      setSelectedFileNames([]);
    }
  };

  return (
    <div className="container">
      <h2>Add Promotional Images</h2>
      <div className="containertwo">
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        /> Select Image
        <button
          className="label"
          onClick={() => document.getElementById("fileInput").click()}
        >
          Choose Files
        </button>
      
        {/* Display selected file names */}
        <div className="selected-files">
          {selectedFileNames.map((fileName, index) => (
            <p key={index}>{fileName}</p>
          ))}
        </div>

        {loading && <p className="loading-message">Loading...</p>}
        {error && <p className="error-message">{error}</p>}
      </div>

      {/* Input for context */}
      <div className="context-input-container">
        <label>Context:</label>
        <input
          type="text"
          value={context}
          onChange={(e) => setContext(e.target.value)}
          placeholder="Enter Screen"
          className="context-input"
        />
      </div>

      {/* Input for order */}
      <div className="order-input-container">
        <label>Order:</label>
        <input
          type="number"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          placeholder="Enter Order"
          className="order-input"
        />
      </div>

      <button className="button" onClick={handleFileUpload}>
        Upload Images
      </button>
    </div>
  );
};

export default AddPromotionalImg;
