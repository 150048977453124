import React from "react";

import "../../../assets/admin/css/styles.css";
import "../../../assets/admin/js/scripts.js";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/icons/logo4.png";
import Navbar from "../../../layout/frontend/Navbar.js";

function Login() {
  return (
    <div>
      <Navbar />
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header text-center ">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{ width: "150px", marginBottom: "20px" }}
                    />
                    <h3 className="font-weight-light my-4">Login</h3>
                  </div>
                  <div className="card-body" >
                    <form>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="inputEmail"
                          type="email"
                          placeholder="name@example.com"
                        />
                        <label htmlFor="inputEmail">Email address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="inputPassword"
                          type="password"
                          placeholder="Password"
                        />
                        <label htmlFor="inputPassword">Password</label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          id="inputRememberPassword"
                          type="checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inputRememberPassword"
                        >
                          Remember Password
                        </label>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <Link className="small" to="/Password">
                          Forgot Password?
                        </Link>
                        <button className="btn btn-primary" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center py-3">
                    <div className="small">
                      <Link to="/Register">Need an account? Sign up!</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Login;
