// import React, { useState } from "react";
// import * as XLSX from "xlsx";
// import axios from "axios";
// import apiConfig from "../../../apiConfig";

// const BulkProductUpload = () => {
//   const [file, setFile] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile) {
//       setFile(selectedFile);
//       readExcel(selectedFile);
//     }
//   };

//   const readExcel = (file) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: "array" });
//       const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
//       const jsonData = XLSX.utils.sheet_to_json(firstSheet);
//       setProducts(jsonData);
//     };
//     reader.readAsArrayBuffer(file);
//   };

//   const handleUpload = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `${apiConfig.baseUrl}${apiConfig.BulkProductUpload}`,
//         products
//       );
//       console.log("Upload success:", response.data);
//     } catch (error) {
//       //   console.error('Error uploading products:', error);
//       alert("Failed to upload products");
//       //   setError('Failed to upload products');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="bulk-upload">
//       <h1>Bulk Product Upload</h1>
//       <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
//       {products.length > 0 && (
//         <div>
//           <h2>Preview Products</h2>
//           <table>
//             <thead>
//               <tr>
//                 {Object.keys(products[0]).map((key) => (
//                   <th key={key}>{key}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {products.map((product, index) => (
//                 <tr key={index}>
//                   {Object.values(product).map((value, idx) => (
//                     <td key={idx}>{value}</td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <button onClick={handleUpload} disabled={loading}>
//             {loading ? "Uploading..." : "Upload Products"}
//           </button>
//         </div>
//       )}
//       {error && <div className="error">{error}</div>}
//     </div>
//   );
// };

// export default BulkProductUpload;

import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import apiConfig from "../../../apiConfig"; // Ensure this is correctly set up

const BulkProductUpload = () => {
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      readExcel(selectedFile);
    }
  };

  // Read Excel file
  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet);
      setProducts(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  // Handle upload
  const handleUpload = async () => {
    setLoading(true);
    setError(null); // Reset error state
    const formData = new FormData();
    formData.append("file", file); // Add the file to formData

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}/admin/upload-bulk-products`, // Correct endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type
          },
        }
      );
      console.log("Upload success:", response.data);
      // Reset states after successful upload
      setProducts([]);
      setFile(null);
    } catch (err) {
      console.error("Error uploading products:", err);
      alert("Failed to upload products. Please try again.");
      setError("Failed to upload products"); // Update error state
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bulk-upload">
      <h1>Bulk Product Upload</h1>
      <input
        type="file"
        name="file" // Ensure this matches the multer configuration on the server
        accept=".xlsx, .xls"
        onChange={handleFileChange}
      />
      {products.length > 0 && (
        <div>
          <h2>Preview Products</h2>
          <table>
            <thead>
              <tr>
                {Object.keys(products[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  {Object.values(product).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleUpload} disabled={loading}>
            {loading ? "Uploading..." : "Upload Products"}
          </button>
        </div>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default BulkProductUpload;
