import AddBrand from "./components/admin/category/AddBrand";
import GetAllBrands from "./components/admin/category/ViewBrand";
import GetCoupons from "./components/admin/pages/GetCoupon";
import BulkProductUpload from "./components/admin/products/BulkProductUpload";
import ViewTrendingProducts from "./components/admin/products/ViewTrandingProduct";

// apiConfig.js
const apiConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  loginEndpoint: "/admin/login",
  resetPasswordEndpoit: "/user/reset-password",
  addCategory: "/admin/addCategory",
  getCategory: "/admin/getcategory",
  updateCategory: "/admin/updatecategory/",
  deleteCategory: "/admin/deletecategory/",
  AddSubCategory: "/admin/addSubcategory",
  getSubCategory: "/admin/getcategory?parentId=",
  deleteSubCategory: "/admin/deletesubcategory/",
  getAllUsers: "/admin/getAllUsers",
  deleteUser: "/admin/deleteUser/",
  getAllProduct: "/user/getAllProducts",
  updateProduct: "/user/update/",
  deleteProducts: "/admin/deleteProducts/",
  addProducts: "/user/new-product",
  createOffers: "/admin/createOffers",
  getPromotinalOffers: "/user/promotionalOffers",
  deleteOffers: "/admin/deleteOffers/",
  updatePromotin: "/admin/editPromotion/",
  getAllOrders: "/admin/getAllOrders",
  SendNotification: "/admin/sendNotification",
  UploadPromotionalOfferImg: "/admin/UploadImagePromotion",
  GetPromotinalImg: "/admin/promotionalImages",
  getProductById: "/admin/getProductById/",
  AddAppNewVerion: "/admin/insertNewVersion",
  getversion: "/user/latestversion",
  ReceivedOrder: "/admin/order/status?status=received",
  Delivered: "/admin/order/status?status=delivered",
  Shipped: "/admin/order/status?status=shipped",
  Cancelled: "/admin/order/status?status=cancelled",
  Processing: "/admin/order/status?status=processing",
  Accepted: "/admin/searchByUpdateStatus/status?updateStatus=Accepted",
  NotAccepted: "/admin/searchByUpdateStatus/status?updateStatus=Not Accepted",
  GetDeliveryCost: "/admin/getDeliveryCost",
  AddDeliverCost: "/admin/deliverCost",
  updateDeliveryCost: "/admin/updateDeliveryCost",
  DeleteDeliverycost: "/admin/deleteDeliverycost",
  OrderAcceptOrReject: "/admin/order/acceptorReject",
  GetBankAllDetails: "/admin/getAlldetails",
  AddBankDetails: "/admin/addBankDetail",
  BankDetailsUpdate: "/admin/updateDetails",
  DeleteBankAccount: "/admin/deleteBankAccount",
  ViewTrendingProducts: "/user/getTrendingProduct",
  ViewTrendingProductsUpdate: `/admin/remove/trendingtag`,
  AddCoupon: "/admin/create/coupons",
  GetCoupons: "/admin/getAll/coupons",
  DeleteCoupons: "/admin/delete/coupons/",
  Reports: "/admin/statistics",
  // ShippingLabel:'/admin/OrderByOrderID',
  GetAcceptedOrders: "/admin/getAcceptedOrders",
  AddBrand: "/admin/addNewBrand",
  GetAllBrands: "/admin/getNewBrands",
  UpdateBrand: "/admin/UpdateBrand",
  DeleteBrand: "/admin/deleteBrand",
  AddSpecifications: "/admin/specification-keys",
  GetAllspecificationkeys: "/admin/GetAllspecificationkeys",
  UpdateSpecifications: "/admin/specification-keys/",
  DeleteSpecifications: "/admin/deletekeys",
  AddFlashSale: "/admin/addToFlashSale",
  GetFlashSale: "/admin/getFlashSales",
  UpdateFlashSale: "/admin/updateFlashSale/",
  DeleteFlashSale: "/admin/deleteFlashSale/",
  BulkProductUpload: "admin/upload-bulk-products",
  ReferralFeature: "/admin/addGiftProduct",
  GiftProductDetails: "/admin/getGiftProducts",
  DeleteGiftProduct: "/admin/deleteGift",
  ImageUploading: "admin/upload-images",
  GetImage: "/admin/images",
  DeleteImage: "admin/images/",

  //  /admin/getcategory?parentId=$ /user/getAllProducts /admin/deleteProducts/
};

export default apiConfig;
