import React, { useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const ImageUploading = () => {
  const [fileInputs, setFileInputs] = useState([0]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e, index) => {
    const files = Array.from(e.target.files);
    const updatedFiles = [...selectedFiles];
    const updatedPreviews = [...previewImages];

    updatedFiles[index] = files;
    updatedPreviews[index] = files.map((file) => URL.createObjectURL(file));

    setSelectedFiles(updatedFiles);
    setPreviewImages(updatedPreviews);
  };

  const addImageField = () => {
    if (fileInputs.length >= 10) {
      alert("You can only add up to 10 images at a time.");
      return;
    }
    setFileInputs([...fileInputs, fileInputs.length]);
  };

  const handleUpload = async () => {
    const allFiles = selectedFiles.flat();
    if (!allFiles.length) {
      alert("Please select files to upload.");
      return;
    }

    const formData = new FormData();
    allFiles.forEach((file) => formData.append("images", file));

    try {
      setUploading(true);
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.ImageUploading}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploadedImages((prev) => [...prev, ...response.data.files]);
      console.log("Files uploaded:", response.data.files);
      alert("Files uploaded successfully!");
    } catch (error) {
      console.error(error);
      alert("An error occurred while uploading files.");
    } finally {
      setUploading(false);
      setSelectedFiles([]);
      setPreviewImages([]);
      setFileInputs([0]);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2 style={{ color: "#333", marginBottom: "10px" }}>Upload Multiple Images</h2>
      <p style={{ marginBottom: "20px", fontSize: "14px", color: "#555" }}>
        You can add up to <strong>10 images</strong> at the same time.
      </p>
      {fileInputs.map((field, index) => (
        <div
          key={field}
          style={{
            marginBottom: "20px",
            border: "1px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => handleFileChange(e, index)}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
            {previewImages[index]?.map((preview, i) => (
              <img
                key={i}
                src={preview}
                alt={`Preview ${i}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))}
          </div>
        </div>
      ))}
     
      <button
        onClick={handleUpload}
        disabled={uploading}
        style={{
          padding: "10px 15px",
          backgroundColor: uploading ? "#ccc" : "#28a745",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: uploading ? "not-allowed" : "pointer",
        }}
      >
        {uploading ? "Uploading..." : "Upload"}
      </button>
      <div style={{ marginTop: "20px" }}>
        <h3 style={{ color: "#333", marginBottom: "10px" }}>Uploaded Images:</h3>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {uploadedImages.map((image, index) => (
            <img
              key={index}
              src={`${apiConfig.baseUrl}/uploads/${image}`}
              alt={`Uploaded ${index}`}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "5px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageUploading;
