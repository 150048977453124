import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  return (
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark"
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            <div className="nav">
              <div className="sb-sidenav-menu-heading">Core</div>

              {/* Order Management Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#orderManagement"
                aria-expanded="false"
                aria-controls="orderManagement"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Order Management
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="orderManagement"
                aria-labelledby="headingOne"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/dashboard">
                    New Order
                  </Link>
                  <Link className="nav-link" to="/admin/DropdownOfStatus">
                    Update Order Status
                  </Link>
                  {/* <Link className="nav-link" to="/admin/ShippingLabel">
                    Shipping Label
                  </Link> */}
                </nav>
              </div>

              {/* Category/Subcategory Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#categoryManagement"
                aria-expanded="false"
                aria-controls="categoryManagement"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Category / Subcategory
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="categoryManagement"
                aria-labelledby="headingTwo"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/add-Category">
                    Add Category
                  </Link>
                  <Link className="nav-link" to="/admin/AddSubCategory">
                    Add Subcategory
                  </Link>
                  <Link className="nav-link" to="/admin/ViewCategory">
                    View Category
                  </Link>
                  <Link className="nav-link" to="/admin/ViewSubcategory">
                    View Subcategory
                  </Link>
                </nav>
              </div>

              {/* Brand Management Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#brandManagement"
                aria-expanded="false"
                aria-controls="brandManagement"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Brand
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="brandManagement"
                aria-labelledby="headingThree"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/AddBrand">
                    Add Brand
                  </Link>
                  <Link className="nav-link" to="/admin/GetAllBrands">
                    View Brand
                  </Link>
                </nav>
              </div>

              {/* Specification Management Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#specificationManagement"
                aria-expanded="false"
                aria-controls="specificationManagement"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Specification
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="specificationManagement"
                aria-labelledby="headingFour"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/AddSpecifications">
                    Add Specification
                  </Link>
                  <Link
                    className="nav-link"
                    to="/admin/GetAllspecificationkeys"
                  >
                    View Specification
                  </Link>
                </nav>
              </div>

              {/* Product Management Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProduct"
                aria-expanded="false"
                aria-controls="collapseProduct"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-columns"></i>
                </div>
                Product
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="collapseProduct"
                aria-labelledby="headingFive"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/AddProduct">
                    Add Product
                  </Link>
                  <Link className="nav-link" to="/admin/ViewProduct">
                    View Product
                  </Link>
                  <Link className="nav-link" to="/admin/ImageUploading">
                    Image Uploading
                  </Link>
                  <Link className="nav-link" to="/admin/ViewImages">
                    View Image
                  </Link>
                  {/* <Link
                    className="nav-link"
                    to="/admin/CreatePromotionalOffers"
                  >
                    Add Promotional Offers
                  </Link> */}
                  <Link className="nav-link" to="/admin/AddPromotionalImg">
                    Add Promotional Image
                  </Link>
                  <Link className="nav-link" to="/admin/ViewPromotional">
                    View Promotional Image
                  </Link>

                  <Link className="nav-link" to="/admin/ViewTrandingProduct">
                    View Trending Product
                  </Link>
                  <Link className="nav-link" to="/admin/BulkProductUpload">
                    BulkProductUpload
                  </Link>
                </nav>
              </div>

              {/* Coupon Management Dropdown */}
              <Link
                className="nav-link collapsed"
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#couponManagement"
                aria-expanded="false"
                aria-controls="couponManagement"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Coupon & Sale
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>
              <div
                className="collapse"
                id="couponManagement"
                aria-labelledby="headingSix"
                data-bs-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <Link className="nav-link" to="/admin/AddCoupon">
                    Add Coupon
                  </Link>
                  <Link className="nav-link" to="/admin/GetCoupon">
                    View Coupon
                  </Link>
                  <Link className="nav-link" to="/admin/AddFlashSale">
                    Add FlashSale
                  </Link>

                  <Link className="nav-link" to="/admin/GetFlashSale">
                    View FlashSale
                  </Link>
                  <Link className="nav-link" to="/admin/ReferralFeature">
                    Add Free Gift
                  </Link>
                  <Link className="nav-link" to="/admin/GiftProductDetails">
                    View Gift
                  </Link>
                </nav>
              </div>

              {/* Additional Links */}

              <Link className="nav-link" to="/admin/Profile">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Users
              </Link>
              <Link className="nav-link" to="/admin/reports">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Reports
              </Link>
              <Link className="nav-link" to="/admin/AddNotification">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Notification
              </Link>
              <Link className="nav-link" to="/admin/UpdateVersion">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Update Version
              </Link>
              <Link className="nav-link" to="/admin/AddDeliveryCost">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Delivery Cost
              </Link>
              <Link className="nav-link" to="/admin/AddBankDetails">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                Add Bank Details
              </Link>

              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
          <div className="sb-sidenav-footer">
            <div className="small">Logged in as:</div>
            Admin
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
