import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import MasterLayout from "./layout/admin/MasterLayout";
import Profile from "./components/admin/Profile";
import Dashboard from "./components/admin/Dashboard";
import Login from "./components/frontend/auth/Login";
import Register from "./components/frontend/auth/Register";
import Password from "./components/frontend/auth/Password";
import Home from "./components/frontend/Home";
import ResetPassword from  "./components/frontend/auth/ResetPassword"
import ViewProduct from "./components/admin/products/ViewProduct";
import AddNotification from "./components/admin/pages/AddNotification";
import ViewTrendingProducts from "./components/admin/products/ViewTrandingProduct";


function App() {

  function refreshPage(){ 
    window.location.reload(); 
  }

  return (
    <div className="App">
      {/* <Router> */}
        <Routes>
        <Route path="/" element={<Home />} />
         <Route path="Register" element={<Register />} />
         <Route path="Login" element={<Login />} />
         <Route path="Password" element={< Password />} />
         <Route path="ResetPassword" element={< ResetPassword />} />
         <Route path="admin/*" element={<MasterLayout />} />
        
        </Routes>
       {/* </Router> */}
    </div>
  );
}



export default App;
