import React, { useState } from "react";
import axios from "axios";
import "./AddNotification.css";
import apiConfig from "../../../apiConfig";

const AddNotification = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [notificationResponse, setNotificationResponse] = useState("");
  const [showModal, setShowModal] = useState(false);

  const sendNotification = () => {
    axios
      .post(`${apiConfig.baseUrl}${apiConfig.SendNotification}`, {
        title,
        body,
        
      })
      .then((response) => {
        setNotificationResponse(response.data);
        console.log("Response:", response.data);
        setShowModal(true);
        setTitle("");
        setBody("");
        alert("Notification sent successfully...");
      })
      .catch((error) => {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error("Server response error:", error.response.data);
        } else if (error.request) {
          // Request was made but no response received
          console.error("No response received:", error.request);
        } else {
          // Error setting up the request
          console.error("Error:", error.message);
        }
      });
  };

  const handleSendNotification = () => {
    sendNotification();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="notification-container">
      <div className="notification">
        <div className="notification-header">
          <h2>Add Notification</h2>
        </div>
        <div className="notification-body">
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Body:</label>
            <textarea value={body} onChange={(e) => setBody(e.target.value)} />
          </div>
          <button onClick={handleSendNotification}>Send Notification</button>

          {/* {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <h3>Notification Sent Successfully!</h3>
                <p>Response: {JSON.stringify(notificationResponse)}</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
