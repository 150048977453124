import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const ViewImages = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch uploaded images from the server
  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GetImage}`
      );

      // Update the state to handle the 'images' array from the response
      if (response.data.images && Array.isArray(response.data.images)) {
        setUploadedImages(response.data.images);
      } else {
        console.error("No images found in the response.");
        alert("Failed to fetch uploaded images.");
      }
      console.log("Fetched images:", response.data.images);
    } catch (error) {
      console.error("Error fetching images:", error);
      alert("Failed to fetch uploaded images.");
    } finally {
      setLoading(false);
    }
  };

  // Delete an image from the server
  const deleteImage = async (filename) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmDelete) return;

    try {
      setLoading(true);
      // Delete image by passing filename in the URL (not in the request body)
      await axios.delete(`${apiConfig.baseUrl}/admin/images/${filename}`);
      setUploadedImages((prevImages) =>
        prevImages.filter((image) => image.filename !== filename)
      );
      alert("Image deleted successfully.");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete the image.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch images on component mount
  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2 style={{ color: "#333", marginBottom: "10px" }}>
        Manage Uploaded Images
      </h2>
      {loading && <p>Loading...</p>}
      {!loading && uploadedImages.length === 0 && (
        <p>No images uploaded yet.</p>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {uploadedImages.map((images, index) => (
          <div
            key={index}
            style={{
              width: "120px",
              textAlign: "center",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "10px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={images.url} // Use image.path for the image URL
              alt={`Uploaded ${index}`}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            />
            <button
              onClick={() => deleteImage(images.filename)} // Use image.filename to delete
              style={{
                padding: "5px 10px",
                backgroundColor: "#dc3545",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewImages;
