import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const GiftProductDetails = () => {
  const [giftProducts, setGiftProducts] = useState([]); // Changed to an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGiftProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      // Send DELETE request to delete the gift product
      await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.DeleteGiftProduct}/${id}`
      );

      // Update the state by removing the deleted product
      setGiftProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== id)
      );

      // Show success message
      alert("Gift product deleted successfully");
    } catch (error) {
      // Log error and show error message
      console.error(
        "Error deleting gift product:",
        error.response?.data || error.message
      );
      alert(
        `Error deleting gift product: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };
  const fetchGiftProducts = async () => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GiftProductDetails}`
      );

      // Log the API Response for debugging purposes
    

      if (response.data?.data) {
        setGiftProducts(response.data.data); // Set the array of products
        console.log("API Response:", response.data);
        setLoading(false);
      } else {
        throw new Error("No gift products found");
      }
    } catch (error) {
      console.error("Error fetching gift products:", error);
      setError(error.message || "Error fetching gift products");
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <table
        style={{
          width: "100%",
          border: "1px solid #ddd",
          borderCollapse: "collapse",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Product ID
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Title
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Category
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              subcategory
            </th>{" "}
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              brand{" "}
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Size{" "}
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Specification{" "}
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Description
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Regular Price
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Price
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Is Gift Product
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Images
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
              }}
            >
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {giftProducts.map((product) => (
            <tr key={product._id}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product._id}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.title}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.category}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.subcategory}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.brand}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.size?.map((size, index) => (
                  <div key={index}>
                    <span>{size}</span>
                  </div>
                ))}
              </td>

              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                    }}
                  >
                    Key
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                    }}
                  >
                    Value
                  </th>
                </tr>
              </thead>

              <tbody>
                {product.specifications && product.specifications.length > 0 ? (
                  product.specifications.map((spec, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                        }}
                      >
                        {spec.key}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                        }}
                      >
                        {spec.values.join(" , ")}{" "}
                        {/* Join multiple values with a comma */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      No specifications available
                    </td>
                  </tr>
                )}
              </tbody>

              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.description}
              </td>

              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.regularPrice}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.price}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.isGiftProduct ? "True" : "False"}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {product.images?.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Product Image ${index + 1}`}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }} // Adjust styling as needed
                    />
                  </div>
                ))}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                <button onClick={() => handleDelete(product._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GiftProductDetails;
