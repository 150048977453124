// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import apiConfig from "../../../apiConfig";
// import "./ViewPromotionalImg.css";

// const ViewPromotionalImg = () => {
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const fetchBannerImages = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${apiConfig.baseUrl}${apiConfig.GetPromotinalImg}`
//       );
//       console.log("Fetched banner images:", response.data);
//       setUploadedImages(response.data);
//     } catch (error) {
//       console.error("Error fetching banner images:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDeleteOffer = async (_id) => {
//     try {
//       await axios.delete(
//         `${apiConfig.baseUrl}${apiConfig.deleteOffers}/${_id}`
//       );
//       fetchBannerImages();
//       alert("image deleted Successfully ");
//     } catch (error) {
//       console.error("Error deleting offer:", error);
//     }
//   };

//   useEffect(() => {
//     fetchBannerImages();
//   }, []);

//   const handleRefresh = () => {
//     fetchBannerImages();
//   };

//   return (
//     <div>
//       <h2 style={{ color: "#808080" }}>View Promotional Images</h2>
//       <button className="btn btn-primary mb-2" onClick={handleRefresh}>
//         Refresh
//       </button>
//       {loading ? (
//         <p>Loading...</p>
//       ) : (
//         <div>
//           {uploadedImages.length > 0 ? (
//             <table className="image-table">
//               <thead>
//                 <tr>
//                   <th>Image</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {uploadedImages.map((offer, index) => (
//                   <tr key={offer._id}>
//                     <td>
//                       <img
//                         src={offer.images[0]}
//                         alt={`Banner ${index + 1}`}
//                         style={{ maxWidth: "200px", maxHeight: "200px" }}
//                       />
//                     </td>
//                     <td>
//                       <button
//                         className="btn btn-outline-danger"
//                         onClick={() => handleDeleteOffer(offer._id)}
//                       >
//                         Delete
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>No banner images uploaded yet.</p>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ViewPromotionalImg;

import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import "./ViewPromotionalImg.css";

const ViewPromotionalImg = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBannerImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GetPromotinalImg}`
      );
      console.log("Fetched banner images:", response.data);
      setUploadedImages(response.data);
      console.log("Uploaded banner images:", response.data);
    } catch (error) {
      console.error("Error fetching banner images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOffer = async (_id) => {
    try {
      await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.deleteOffers}/${_id}`
      );
      fetchBannerImages();
      alert("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  useEffect(() => {
    fetchBannerImages();
  }, []);

  const handleRefresh = () => {
    fetchBannerImages();
  };

  return (
    <div>
      <h2 style={{ color: "#808080" }}>View Promotional Images</h2>
      <button className="btn btn-primary mb-2" onClick={handleRefresh}>
        Refresh
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {uploadedImages.length > 0 ? (
            <table className="image-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Context</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadedImages.map((offer, index) => (
                  <tr key={offer._id}>
                    <td>
                      {/* Container for images with column layout */}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {offer.images.map((image, imgIndex) => (
                          <img
                            key={imgIndex} // Use imgIndex as a key for each image
                            src={image}
                            alt={`Banner ${index + 1} - ${imgIndex + 1}`}
                            style={{
                              maxWidth: "50px", // Adjust image width
                              maxHeight: "50px", // Adjust image height
                              marginBottom: "10px", // Space between images in column
                            }}
                          />
                        ))}
                      </div>
                    </td>
                    <td>{offer.context || "N/A"}</td> {/* Display context */}
                    <td>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleDeleteOffer(offer._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No banner images uploaded yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewPromotionalImg;
