import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import QRCode from "react-qr-code";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactPaginate from "react-paginate";
import apiConfig from "../../../apiConfig";
import "../page.css";
import { useNavigate } from "react-router-dom";
import DropdownOfStatus from "./DropdownOfStatus";
import BackButton from "./BackButton";

const Processing = () => {
  const UpiId = localStorage.getItem("UpiId");
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [ordersPerPage] = useState(10);
  const tableRef = useRef(null);

  const fetchOrders = async () => {
    setLoading(true);
    console.log(`${apiConfig.baseUrl}/${apiConfig.Shipped}`);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}/${apiConfig.Shipped}`
      );
      setOrders(response.data);
      setFilteredOrders(response.data);
      setLoading(false);
      alert("Orders updated successfully")
    } catch (err) {
      setError("");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    let filtered = orders;

    if (filterOrderId) {
      filtered = filtered.filter((order) =>
        order.orderID.includes(filterOrderId)
      );
    }

    if (filterProductName) {
      filtered = filtered.filter((order) =>
        order.items.some(
          (item) =>
            item.productId &&
            item.productId.title
              .toLowerCase()
              .includes(filterProductName.toLowerCase())
        )
      );
    }

    setFilteredOrders(filtered);
  }, [filterOrderId, filterProductName, orders]);

  const downloadPDF = async () => {
    const input = tableRef.current;
    if (!input) return;

    const canvas = await html2canvas(input, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("order_list.pdf");
  };

  const handleRefresh = async () => {
    await fetchOrders();
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  //  Track order and update if needed
  const handleNavigate = () => {
    navigate("/admin/OrderTracking");
  };

  //  Track order and update if needed

  //  change status
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [newPaymentStatus, setNewPaymentStatus] = useState("");
  const [newPaymentMode, setNewPaymentMode] = useState("");

  const handleStatusChange = async (orderID, paymentStatus) => {
    setLoading(true);
    try {
      const nextStatus = getNextStatus(selectedStatus);

      if (nextStatus === newStatus) {
        if (newStatus === "delivered" && paymentStatus === "Paid") {
          setModalMessage(
            "Cannot update to 'delivered' status. The payment status is not 'paid'."
          );
          setShowModal(true);
        } else {
          await axios.put(
            `${apiConfig.baseUrl}/admin/order/${orderID}/updateStatus`,
            { status: newStatus }
          );

          await fetchOrders();
          setModalMessage("Order status updated successfully.");
          // setShowModal(true);
          alert("Order status updated successfully")
        }
      } else {
        console.error("Cannot update to the selected status:", newStatus);
      }
    } catch (err) {
      console.error("Error updating order status:", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentStatusChange = async (orderID) => {
    setLoading(true);
    try {
      await axios.put(`${apiConfig.baseUrl}/admin/update/paymentstatus`, {
        orderId: orderID,
        paymentStatus: newPaymentStatus,
        paymentMode: newPaymentMode,
      });
      await fetchOrders();
      setModalMessage("Payment status updated successfully.");
      setShowModal(true);
    } catch (err) {
      console.error("Error updating payment status:", err);
    } finally {
      setLoading(false);
    }
  };

  // const handleStatusChange = async (orderID,) => {
  //   setLoading(true);
  //   try {
  //     const nextStatus = getNextStatus(selectedStatus);

  //     if (nextStatus === newStatus) {

  //         await axios.put(
  //           `${apiConfig.baseUrl}/admin/order/${orderID}/updateStatus`,
  //           { status: newStatus }
  //         );

  //         await fetchOrders();
  //         setModalMessage("Order status updated successfully.");
  //         setShowModal(true);
  //       }
  //      else {
  //       console.error("Cannot update to the selected status:", newStatus);
  //     }
  //   } catch (err) {
  //     console.error("Error updating order status:", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getStatusColor = (status) => {
    switch (status) {
      case "received":
        return "blue";
      case "processing":
        return "#EF9B0F";
      case "shipped":
        return "green";
      case "delivered":
        return "purple";
      case "cancelled":
        return "#C40233";
      default:
        return "black";
    }
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "received":
        return "processing";
      case "processing":
        return "shipped";
      case "shipped":
        return "delivered";
      case "delivered":
        return "";
      case "cancelled":
        return "";
      default:
        return "";
    }
  };

  // end status change

  const offset = currentPage * ordersPerPage;
  const currentOrders = filteredOrders.slice(offset, offset + ordersPerPage);
  const pageCount = Math.ceil(filteredOrders.length / ordersPerPage);

  if (loading) return <div>Loading...</div>;
  if (error) return <div> {error}</div>;

  return (
    <div>
      <BackButton />
      <div className="filters">
        <label>
          Filter by Order ID:
          <input
            type="text"
            value={filterOrderId}
            onChange={(e) => setFilterOrderId(e.target.value)}
          />
        </label>
        {/* <label>
          Filter by Product Name:
          <input
            type="text"
            value={filterProductName}
            onChange={(e) => setFilterProductName(e.target.value)}
          />
        </label> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "20px",
          }}
        >
          <DropdownOfStatus />
        </div>
        <div>
          <button
            onClick={handleNavigate}
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          >
            Order Track and Update
          </button>
        </div>
        <div style={{ textAlign: "right" }}>
          {/* <button
            onClick={downloadPDF}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#007BFF",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#0056b3")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#007BFF")
            }
          >
            Download Full List of orders as PDF
          </button> */}
          <button
            onClick={handleRefresh}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#28A745",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#218838")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#28A745")
            }
          >
            Refresh Data
          </button>
        </div>
      </div>
      <div ref={tableRef}>
        <h1>List Of Shipped Order</h1>
        <table>
          <thead>
            <tr>
              <th>Items</th>

              <th> Product Price</th>
              <th>Shipping Price</th>
              <th>Coupon Discount</th>

              <th>Total Amount</th>
              <th>Order date</th>
              <th>Order ID</th>
              <th>Delivery Address</th>
              <th>Payment Status</th>
              <th>Payment Mode</th>
              <th>Payment QR</th>
              <th>Status</th>
              <th>User ID</th>
              <th>Actions Change Product status</th>
              <th>Change status/mode</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders.map((order) => (
              <tr key={order._id}>
                <td>
                  <table className="sub-table">
                    <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th> Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.items.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>
                              {item?.variation?.variationId
                                ? item?.variation?.variationId
                                : item.productId || "N/A"}
                            </td>
                            <td>
                              {item?.variation?.variationTitle
                                ? item?.variation?.variationTitle
                                : item.title || "N/A"}
                            </td>
                            <td>{item.quantity}</td>
                            <td>₹{item.totalPrice}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>

                {/* <td>₹{order.totalPrice ?? 0}</td>
                <td>₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}</td> */}
                {order.items.map((item, index) => {
                  const productPrice = item.price || 0; // Get product price
                  const variationPrice = item.variation?.VariationPrice || 0; // Get variation price if it exists

                  // Total price logic
                  let totalPrice = productPrice; // Start with the product price
                  if (item.productId && item.variation) {
                    totalPrice += variationPrice; // Add variation price only if productId and variation exist
                  }

                  return (
                    <tr key={index}>
                      <td>₹{item.totalPrice}</td>
                    </tr>
                  );
                })}
                <td>₹{order.shippingCost ?? 0}</td>
            
                {order.items.map((item, index) => (
                    <tr key={index}>
                      
                      <td>₹{item.couponDiscount}</td>
                    </tr>
                  ))}

                <td>₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}</td>

                <td>
                  {new Date(order.createdAt).toLocaleDateString()}
                  <br />
                  {new Date(order.createdAt).toLocaleTimeString()}
                </td>
                <td>{order.orderID}</td>

                <td>
                  {order.address ? (
                    <>
                      <div>Name: {order.address.name || "N/A"}</div>
                      <div>Address: {order.address.location || "N/A"}</div>
                      <div>{order.address.city || "N/A"}</div>
                      <div>{order.address.state || "N/A"}</div>
                      <div>{order.address.pincode || "N/A"}</div>
                      <div>Phone: {order.address.phone || "N/A"}</div>
                      <div>Email: {order.address.email || "N/A"}</div>
                      <div>
                        Delivery Days: {order.address.deliveryDays || "N/A"}
                      </div>
                    </>
                  ) : (
                    "Address information is not available"
                  )}
                </td>
                <td>{order.paymentStatus}</td>
                <td>{order.paymentMode}</td>
                <td>
                  <QRCode
                    value={`upi://pay?pa=${UpiId}&pn=BharatCod&am=${
                      (order.totalPrice ?? 0) + (order.shippingCost ?? 0)
                    }&cu=INR&tr=${
                      order.orderID
                    }&tn=Order%20ID%3A%20${encodeURIComponent(order.orderID)}`}
                    size={100}
                  />
                </td>
                <td>{order.status}</td>
                <td>{order.userId}</td>
                <td>
                  {order.status !== "delivered" && (
                    <>
                      <select
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value={getNextStatus(order.status)}>
                          {getNextStatus(order.status)}
                        </option>
                      </select>
                      <button
                        style={{
                          padding: "1px 2px",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",
                          backgroundColor: "green",
                          border: "none",
                          borderRadius: "5px",
                          cursor: loading ? "not-allowed" : "pointer",
                          margin: "5px",
                          opacity: loading ? 0.6 : 1,
                          transition: "background-color 0.3s ease",
                        }}
                        onClick={() => {
                          setSelectedStatus(order.status);
                          handleStatusChange(order.orderID);
                        }}
                      >
                        Update Status
                      </button>
                    </>
                  )}
                </td>

                <td>
                  {order.status === "shipped" && (
                    <>
                      <select
                        value={newPaymentMode}
                        onChange={(e) => setNewPaymentMode(e.target.value)}
                      >
                        <option value="">Select Payment Mode</option>
                        <option value="Online">Online</option>
                        <option value="Cash">Cash</option>
                      </select>
                      <select
                        value={newPaymentStatus}
                        onChange={(e) => setNewPaymentStatus(e.target.value)}
                      >
                        <option value="">Select Payment Status</option>
                        <option value="Paid">Paid</option>
                        <option value="Pending">pending</option>
                      </select>
                      <button
                        onClick={() => handlePaymentStatusChange(order.orderID)}
                      >
                        Update Payment
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
      {showModal && (
        <div className="modal ">
          <p style={{ color: "green" }}>{modalMessage}</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Processing;
