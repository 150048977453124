import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import "./AddFlashSale.css";

const AddFlashSale = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [images, setImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([""]);
  const [selectedBrands, setSelectedBrands] = useState([""]);
  const [variationIds, setVariationIds] = useState([""]); // New state for variation IDs

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setCategories(categoryResponse.data);

        const brandResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(brandResponse.data);
      } catch (error) {
        console.error("Error fetching categories and brands:", error);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const isValidObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id.trim());

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();

    formData.append("isVariation", data.isVariation === "true");

    // Process product IDs
    if (data.products) {
      data.products
        .split(",")
        .map((id) => id.trim())
        .filter(isValidObjectId)
        .forEach((id) => formData.append("products", id));
    }

    // Process variation IDs from state
    if (data.isVariation === "true") {
      variationIds.forEach((id) => {
        if (isValidObjectId(id)) {
          formData.append("variations", id); // Append each valid variation ID
        }
      });
    }

    // Handle categories
    selectedCategories.forEach((category) => {
      if (category) formData.append("categories", category);
    });

    // Handle brands
    selectedBrands.forEach((brand) => {
      if (brand) formData.append("brands", brand);
    });

    formData.append("discountPercentage", data.discountPercentage || 0);
    formData.append("saleStartTime", data.saleStartTime || "");
    formData.append("saleEndTime", data.saleEndTime || "");
    formData.append("title", data.title||"");
    formData.append("stock", data.stock||"");


    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.AddFlashSale}`,
        formData,
        
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
        
      );
      console.log("Response:", response.data);
      alert("Flash sale added successfully!");
      reset();
      setImages([]);
      setSelectedCategories([""]);
      setSelectedBrands([""]);
      setVariationIds([""]); // Reset variation IDs after submission
    } catch (error) {
      console.error("Error:", error.response?.data);
      setErrorMessage(error.response?.data?.message || "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addCategoryField = () => {
    setSelectedCategories([...selectedCategories, ""]);
  };

  const addBrandField = () => {
    setSelectedBrands([...selectedBrands, ""]);
  };

  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = value;
    setSelectedCategories(updatedCategories);
  };

  const handleBrandChange = (index, value) => {
    const updatedBrands = [...selectedBrands];
    updatedBrands[index] = value;
    setSelectedBrands(updatedBrands);
  };

  return (
    <div className="flash-sale-form">
      <h2>Add Flash Sale</h2>

      {errorMessage && <p className="error">{errorMessage}</p>}

      {/* <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          {...register("title", { required: true })}
          type="text"
          placeholder="Enter Flash Sale Title"
          className="form-control"
        />
        {errors.title && <span>This field is required</span>}
      </div> */}

      <div>
          <label>Title</label>
          <input
            type="string"
            step="0.01"
            {...register("title")}
            placeholder="Enter Discount Percentage (Optional)"
          />
        </div>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div>
          <label>Product IDs</label>
          <input
            type="text"
            {...register("products")}
            placeholder="Enter Product IDs separated by commas"
          />
        </div>

        <div>
          <label>Categories</label>
          {selectedCategories.map((category, index) => (
            <select
              key={index}
              value={category}
              onChange={(e) => handleCategoryChange(index, e.target.value)}
            >
              <option value="">Select Category (Optional)</option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          ))}
          <button type="button" onClick={addCategoryField}>
            Add Category
          </button>
        </div>

        <div>
          <label>Brands</label>
          {selectedBrands.map((brand, index) => (
            <select
              key={index}
              value={brand}
              onChange={(e) => handleBrandChange(index, e.target.value)}
            >
              <option value="">Select Brand (Optional)</option>
              {brands.map((brand) => (
                <option key={brand.name} value={brand.name}>
                  {brand.name}
                </option>
              ))}
            </select>
          ))}
          <button type="button" onClick={addBrandField}>
            Add Brand
          </button>
        </div>

        <div>
          <label>Discount Percentage</label>
          <input
            type="number"
            step="0.01"
            {...register("discountPercentage", { min: 1, max: 99 })}
            placeholder="Enter Discount Percentage (Optional)"
          />
        </div>

        <div>
          <label>Stock</label>
          <input
            type="number"
            step="0.01"
            {...register("stock")}
            placeholder="Enter Discount Percentage (Optional)"
          />
        </div>
{/* 
        <div className="form-group">
          <label htmlFor="stock">Stock</label>
          <input
            {...register("stock", { required: true })}
            type="number"
            placeholder="Enter Stock Quantity"
            className="form-control"
          />
          {errors.stock && <span>This field is required</span>}
        </div> */}

        <div>
          <label>Sale Start Time</label>
          <input type="datetime-local" {...register("saleStartTime")} />
        </div>

        <div>
          <label>Sale End Time</label>
          <input type="datetime-local" {...register("saleEndTime")} />
        </div>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Add to Flash Sale"}
        </button>
      </form>
    </div>
  );
};

export default AddFlashSale;

// import React, { useState } from "react";
// import { useForm } from "react-hook-form";

// const AddFlashSale = () => {
//   const [selectedCategories, setSelectedCategories] = useState([""]);
//   const [selectedBrands, setSelectedBrands] = useState([""]);
//   const [errorMessage, setErrorMessage] = useState(null);
//   const { register, handleSubmit, formState: { errors }, isSubmitting } = useForm();

//   const categories = [{ _id: 1, name: "Electronics" }, { _id: 2, name: "Clothing" }];  // Example categories
//   const brands = [{ _id: 1, name: "Brand A" }, { _id: 2, name: "Brand B" }];  // Example brands

//   const handleCategoryChange = (index, value) => {
//     const updatedCategories = [...selectedCategories];
//     updatedCategories[index] = value;
//     setSelectedCategories(updatedCategories);
//   };

//   const handleBrandChange = (index, value) => {
//     const updatedBrands = [...selectedBrands];
//     updatedBrands[index] = value;
//     setSelectedBrands(updatedBrands);
//   };

//   const addCategoryField = () => {
//     setSelectedCategories([...selectedCategories, ""]);
//   };

//   const addBrandField = () => {
//     setSelectedBrands([...selectedBrands, ""]);
//   };

//   const onSubmit = (data) => {
//     console.log(data);
//     // API call logic here to submit the flash sale data
//   };

//   return (
//     <div className="add-flash-sale">
//       <h1>Add Flash Sale</h1>
//       <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
//         <div className="form-group">
//           <label htmlFor="products">Products</label>
//           <input
//             {...register("products", { required: true })}
//             type="text"
//             placeholder="Enter Product IDs (comma separated)"
//             className="form-control"
//           />
//           {errors.products && <span>This field is required</span>}
//         </div>

//         <div className="form-group">
//           <label htmlFor="title">Title</label>
//           <input
//             {...register("title", { required: true })}
//             type="text"
//             placeholder="Enter Flash Sale Title"
//             className="form-control"
//           />
//           {errors.title && <span>This field is required</span>}
//         </div>

//         <div className="form-group">
//           <label htmlFor="stock">Stock</label>
//           <input
//             {...register("stock", { required: true })}
//             type="number"
//             placeholder="Enter Stock Quantity"
//             className="form-control"
//           />
//           {errors.stock && <span>This field is required</span>}
//         </div>

//         <div className="form-group">
//           <label htmlFor="isVariation">Is Variation?</label>
//           <select
//             {...register("isVariation")}
//             className="form-control"
//           >
//             <option value="false">No</option>
//             <option value="true">Yes</option>
//           </select>
//         </div>

//         {selectedCategories.map((category, index) => (
//           <div key={index} className="form-group">
//             <label htmlFor="categories">Categories</label>
//             <select
//               value={category}
//               onChange={(e) => handleCategoryChange(index, e.target.value)}
//               className="form-control"
//             >
//               {categories.map((cat) => (
//                 <option key={cat._id} value={cat._id}>
//                   {cat.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         ))}
//         <button type="button" onClick={addCategoryField} className="btn btn-secondary">
//           Add Category
//         </button>

//         {selectedBrands.map((brand, index) => (
//           <div key={index} className="form-group">
//             <label htmlFor="brands">Brands</label>
//             <select
//               value={brand}
//               onChange={(e) => handleBrandChange(index, e.target.value)}
//               className="form-control"
//             >
//               {brands.map((br) => (
//                 <option key={br._id} value={br._id}>
//                   {br.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         ))}
//         <button type="button" onClick={addBrandField} className="btn btn-secondary">
//           Add Brand
//         </button>

//         <div className="form-group">
//           <label htmlFor="discountPercentage">Discount Percentage</label>
//           <input
//             {...register("discountPercentage", { required: true })}
//             type="number"
//             placeholder="Enter Discount Percentage"
//             className="form-control"
//           />
//           {errors.discountPercentage && <span>This field is required</span>}
//         </div>

//         <div className="form-group">
//           <label htmlFor="saleStartTime">Sale Start Time</label>
//           <input
//             {...register("saleStartTime", { required: true })}
//             type="datetime-local"
//             className="form-control"
//           />
//           {errors.saleStartTime && <span>This field is required</span>}
//         </div>

//         <div className="form-group">
//           <label htmlFor="saleEndTime">Sale End Time</label>
//           <input
//             {...register("saleEndTime", { required: true })}
//             type="datetime-local"
//             className="form-control"
//           />
//           {errors.saleEndTime && <span>This field is required</span>}
//         </div>

//         <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
//           {isSubmitting ? "Submitting..." : "Submit Flash Sale"}
//         </button>

//         {errorMessage && <div className="error-message">{errorMessage}</div>}
//       </form>
//     </div>
//   );
// };

// export default AddFlashSale;
