import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const Statistics = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.Reports}`
        );
        setStats(response.data);
        console.log("Statistics data:", response.data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
        setError(`Failed to fetch statistics: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Defensive checks to prevent errors when stats or nested objects are undefined
  const {
    userCount = 0,
    totalSales = 0,
    orderCount = 0,
    orderStatusCounts = {},
    productCount = 0,
    productStockDetails = []
  } = stats || {}; // Safely default to empty values

  return (
    <div className="statistics-panel">
      <h1>Admin Panel Statistics</h1>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {/* Overall Statistics */}
          <tr>
            <td>User Count</td>
            <td>{userCount}</td>
          </tr>
          <tr>
            <td>Total Sales</td>
            <td>{totalSales}</td>
          </tr>
          <tr>
            <td>Order Count</td>
            <td>{orderCount}</td>
          </tr>

          {/* Order Status Counts */}
          <tr>
            <th colSpan="2">Order Status Counts</th>
          </tr>
          {Object.entries(orderStatusCounts).map(([status, count]) => (
            <tr key={status}>
              <td>{status.charAt(0).toUpperCase() + status.slice(1)}</td>
              <td>{count}</td>
            </tr>
          ))}

          {/* Product Statistics */}
          <tr>
            <td>Product Count</td>
            <td>{productCount}</td>
          </tr>

          {/* Product Stock Details */}
          <tr>
            <th colSpan="2">Product Stock Details</th>
          </tr>
          {productStockDetails.length > 0 ? (
            productStockDetails.map((product, index) => (
              <tr key={index}>
                <td>{product.title}</td>
                <td>{product.stock}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No products found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Statistics;
