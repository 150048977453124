// import React from 'react';
// import ReactDOM from 'react-dom';

// // Import createRoot from react-dom/client
// import { createRoot } from 'react-dom/client';

// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.js';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from './components/frontend/auth/Login';

// // Create root using createRoot from react-dom/client
// const root = createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';

// Import createRoot from react-dom/client
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

// Import BrowserRouter from react-router-dom for routing
import { BrowserRouter as Router } from 'react-router-dom';

// Create root using createRoot from react-dom/client
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
