import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";
const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://www.yourwebsite.com";

const GetFlashSale = () => {
  const [flashSales, setFlashSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedFlashSale, setSelectedFlashSale] = useState(null);
  const [formData, setFormData] = useState({
    salePrice: "",
    saleStartTime: "",
    saleEndTime: "",
    isVariation: false,
    variationId: "",
  });
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    const fetchFlashSales = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetFlashSale}`
        );
        setFlashSales(response.data.flashSales);
        console.log("Flash sales", response.data);
      } catch (error) {
        console.error("Error fetching flash sales:", error);
        setError(error.response?.data?.message || "Error fetching flash sales");
      } finally {
        setLoading(false);
      }
    };

    fetchFlashSales();
  }, []);

  const handleDelete = (flashSaleId) => {
    axios
      .delete(`${apiConfig.baseUrl}/admin/deleteFlashSale/${flashSaleId}`)
      .then((response) => {
        console.log("Flash sale deleted successfully");
        alert("Flash sale deleted successfully");

        // Update the state to remove the deleted flash sale
        setFlashSales((prevFlashSales) =>
          prevFlashSales.filter((sale) => sale.id !== flashSaleId)
        );
      })
      .catch((error) => {
        console.error("Error deleting flash sale:", error);
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { salePrice, saleStartTime, saleEndTime, isVariation, variationId } =
      formData;
    const flashSaleId = selectedFlashSale._id;

    setActionLoading(true);

    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.UpdateFlashSale}${flashSaleId}`,
        { salePrice, saleStartTime, saleEndTime, isVariation, variationId }
      );
      setFlashSales((prevSales) =>
        prevSales.map((sale) =>
          sale._id === flashSaleId ? response.data.flashSale : sale
        )
      );
      alert("Flash sale updated successfully.");
      setSelectedFlashSale(null);
    } catch (error) {
      console.error("Error updating flash sale:", error);
      alert(error.response?.data?.message || "Error updating flash sale.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div>
      <h1>Flash Sales</h1>
      {flashSales.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Title</th>

              <th>Category</th>
              <th>Brands</th>
              <th>originalPrice</th>
              <th>Discount</th>
              <th>Discount price</th>

              <th>Sale Start Time</th>
              <th>Sale End Time</th>
              <th>Images</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {flashSales.map((sale) => (
              <tr key={sale._id}>
                <td>
                  {sale.products.length > 0 ? (
                    sale.products.map((product, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between product IDs
                          textAlign: "center",
                        }}
                      >
                        {product.productId ? product.productId : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>
                  {sale.products.length > 0 ? (
                    sale.products.map((product, productIndex) => (
                      <div
                        key={productIndex}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between titles
                          width: "130px",
                          textAlign: "center",
                        }}
                      >
                        {product.title ? product.title : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>
                  {sale.categories.length > 0 ? (
                    sale.categories.map((category, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between category names
                          textAlign: "center",
                        }}
                      >
                        {category.name ? category.name : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>
                  {sale.brands.length > 0 ? (
                    sale.brands.map((brand, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between brand names
                          textAlign: "center",
                        }}
                      >
                        {brand.name ? brand.name : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>
                  {sale.products.length > 0 ? (
                    sale.products.map((product, productIndex) => (
                      <div
                        key={productIndex}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between each price
                          textAlign: "center",
                        }}
                      >
                        {product.originalPrice ? product.originalPrice : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td style={{ textAlign: "center" }}>
                  {sale.discountPercentage}
                </td>
                <td>
                  {sale.products.length > 0 ? (
                    sale.products.map((product, productIndex) => (
                      <div
                        key={productIndex}
                        style={{
                          border: "1px solid lightgray", // Adds a 1px solid black border
                          padding: "5px", // Adds padding around the text
                          marginBottom: "5px", // Adds spacing between each price
                          textAlign: "center",
                        }}
                      >
                        {product.discountedPrice
                          ? product.discountedPrice
                          : "N/A"}
                      </div>
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>{new Date(sale.saleStartTime).toLocaleString()}</td>
                <td>{new Date(sale.saleEndTime).toLocaleString()}</td>

                <td>
                  {Array.isArray(sale.images) && sale.images.length > 0 ? (
                    // Display product images if available
                    sale.images.map((img, index) => (
                      <img
                        key={index}
                        src={img} // Assuming `img` contains the full URL
                        alt={`Product Image ${index + 1}`}
                        style={{ width: "50px", height: "50px", margin: "5px" }}
                      />
                    ))
                  ) : Array.isArray(sale.categories) &&
                    sale.categories.length > 0 &&
                    sale.categories[0].image ? (
                    // If no product images, check for category images
                    sale.categories.map((category, index) => (
                      <img
                        key={index}
                        src={
                          category.image.startsWith("http")
                            ? category.image
                            : `${BASE_URL}/${category.image}` // Use dynamic BASE_URL here
                        }
                        alt={`Category Image ${index + 1}`}
                        style={{ width: "50px", height: "50px", margin: "5px" }}
                      />
                    ))
                  ) : Array.isArray(sale.brands) &&
                    sale.brands.length > 0 &&
                    sale.brands[0].image ? (
                    // If no product or category images, check for brand images
                    sale.brands.map((brand, index) => (
                      <img
                        key={index}
                        src={
                          brand.image.startsWith("http")
                            ? brand.image
                            : `${BASE_URL}/${brand.image}` // Use dynamic BASE_URL here
                        }
                        alt={`Brand Image ${index + 1}`}
                        style={{ width: "50px", height: "50px", margin: "5px" }}
                      />
                    ))
                  ) : (
                    <p></p>
                  )}
                </td>

                <td>
                  <button onClick={() => handleDelete(sale.id)}>Delete</button>

                  {/* {actionLoading ? "Deleting..." : "Delete"} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No flash sales found.</p>
      )}

      {selectedFlashSale && (
        <form
          onSubmit={handleUpdate}
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            marginTop: "20px",
          }}
        >
          <h3>Edit Flash Sale</h3>
          <label>
            Sale Price:
            <input
              type="number"
              name="salePrice"
              value={formData.salePrice}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Sale Start Time:
            <input
              type="datetime-local"
              name="saleStartTime"
              value={formData.saleStartTime}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Sale End Time:
            <input
              type="datetime-local"
              name="saleEndTime"
              value={formData.saleEndTime}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Is Variation:
            <input
              type="checkbox"
              name="isVariation"
              checked={formData.isVariation}
              onChange={handleChange}
              style={{ margin: "0 10px 0 0" }}
            />
          </label>
          {formData.isVariation && (
            <label>
              Variation ID:
              <input
                type="text"
                name="variationId"
                value={formData.variationId}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
              />
            </label>
          )}
          <button
            type="submit"
            disabled={actionLoading}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            {actionLoading ? "Updating..." : "Update Flash Sale"}
          </button>
          <button
            type="button"
            onClick={() => setSelectedFlashSale(null)}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
};

export default GetFlashSale;
