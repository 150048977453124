import React, { useState, useEffect } from "react";
import "../../assets/admin/css/styles.css";
import "../../assets/admin/js/scripts.js";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/icons/logo4.png";
import Navbar from "../../layout/frontend/Navbar.js";
import axios from "axios";

import apiConfig from "../../apiConfig";

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      navigate(`/ResetPassword?token=${token}`);
    }
  }, [navigate]);

  // https://www.bharatcod.com/admin/login  ${apiConfig.baseUrl}${apiConfig.loginEndpoint}

  const login = async () => {
    const url = `${apiConfig.baseUrl}${apiConfig.loginEndpoint}`;
    // console.log('URL:', url);
    const payload = {
      email,
      password,
    };

    try {
      const response = await axios.post(url, payload);
      // console.log('Login successful', response.data);

      const token = response.data.token;
      const user = response.data.admin;

      if (token && user) {
        localStorage.setItem("authToken", token);
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/admin");
      } else {
        // console.error('Login failed: Invalid response format');
        setError("Login failed: Invalid response format");
      }
    } catch (error) {
      // console.error('Error:', error);
      setError("Login failed. Please check your credentials.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <div>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container" style={{width:"400px"}}>
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div
                  className="card shadow-lg border-0 rounded-lg mt-5"
                  style={{ width: "300px", marginLeft:"-70px" }}
                >
                  <div className="card-header text-center">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{ width: "150px", marginBottom: "20px" }}
                    />
                    <h3 className="font-weight-light my-4">Admin Login</h3>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="inputEmail"
                          type="email"
                          placeholder="name@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="inputEmail">Email address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          id="inputPassword"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <label htmlFor="inputPassword">Password</label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          id="inputRememberPassword"
                          type="checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inputRememberPassword"
                        >
                          Remember Password
                        </label>
                      </div>
                      {error && (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      )}
                      <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <Link className="small" to="/Password">
                          Forgot Password?
                        </Link>
                        <button className="btn btn-primary" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;

{
  /* <Navbar /> */
}
