// import React, { useState, useEffect } from "react";
// import { useParams, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import apiConfig from "../../../apiConfig";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import "bootstrap/dist/css/bootstrap.min.css";

// const EditProducts = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const location = useLocation();
//   const { product } = location.state;
//   const [selectedBrand, setSelectedBrand] = useState("");
//   const [specifications, setSpecifications] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [brands, setBrands] = useState([]);
//   const [parentIds, setParentIds] = useState([]);
//   const [selectedParentId, setSelectedParentId] = useState("");
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [subcategories, setSubcategories] = useState([]);
//   const [formData, setFormData] = useState({
//     title: "",
//     brand: "",
//     description: "",
//     regularPrice: "",
//     salePrice: "",
//     stock: "",
//     productimage1: null,
//     productimage2: null,
//     productimage3: null,
//     category: "",
//   });
//   const [specificationKeys, setSpecificationKeys] = useState([]);

//   useEffect(() => {
//     const fetchBrands = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
//         );
//         setBrands(response.data);
//         if (response.data.length > 0) {
//           setSelectedBrand("");
//         }
//       } catch (error) {
//         console.error("Error fetching brands:", error);
//       }
//     };

//     fetchBrands();
//   }, []);

//   useEffect(() => {
//     const fetchParentIds = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.getCategory}`
//         );
//         setParentIds(response.data);
//         if (response.data.length > 0) {
//           setSelectedParentId(response.data[0]._id);
//         }
//       } catch (error) {
//         console.error("Error fetching parent IDs:", error);
//       }
//     };

//     fetchParentIds();
//   }, []);

//   useEffect(() => {
//     const fetchSubcategories = async () => {
//       try {
//         if (selectedParentId) {
//           const response = await axios.get(
//             `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
//           );
//           setSubcategories(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching subcategories:", error);
//       }
//     };
//     fetchSubcategories();
//   }, [selectedParentId]);

//   useEffect(() => {
//     const fetchSpecifications = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
//         );
//         if (response?.data?.data && Array.isArray(response?.data?.data)) {
//           const keys = response.data.data.map((item) => item.key);
//           setSpecificationKeys(keys);
//         } else {
//           console.error(
//             "The API response data is either undefined or not an array."
//           );
//           setSpecificationKeys([]);
//         }
//       } catch (error) {
//         console.error("Error fetching specifications:", error);
//         setSpecifications([]);
//       }
//     };

//     fetchSpecifications();
//   }, []);

//   useEffect(() => {
//     if (product) {
//       setFormData({
//         brand: product.brand,
//         title: product.title,
//         description: product.description,
//         regularPrice: product.regularPrice,
//         salePrice: product.salePrice,
//         stock: product.stock,
//         productimage1: product.productimage1,
//         productimage2: product.productimage2,
//         productimage3: product.productimage3,
//         category: product?.category,
//       });

//       // Set existing specifications from the product
//       if (product.specifications && product.specifications.length > 0) {
//         setSpecifications(product.specifications);
//         setSpecificationKeys(product.specifications.map((spec) => spec.key));
//       }

//       setSelectedSubcategory(product?.subcategory || "");
//     }
//   }, [product]);

//   const handleInputChange = (e) => {
//     const { name, value, files } = e.target;
//     if (
//       name === "productimage1" ||
//       name === "productimage2" ||
//       name === "productimage3"
//     ) {
//       setFormData({
//         ...formData,
//         [name]: files[0],
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   const handleSpecificationChange = (specIndex, updatedSpec) => {
//     const updatedSpecifications = [...specifications];
//     updatedSpecifications[specIndex] = updatedSpec;
//     setSpecifications(updatedSpecifications);
//   };

//   const addSpecification = () => {
//     setSpecifications([...specifications, { key: "", values: [""] }]);
//   };

//   const removeSpecification = (index) => {
//     setSpecifications(specifications.filter((_, i) => i !== index));
//   };

//   const handleSpecificationValueChange = (specIndex, valueIndex, e) => {
//     const { value } = e.target;
//     const updatedSpecifications = [...specifications];
//     updatedSpecifications[specIndex].values[valueIndex] = value;
//     setSpecifications(updatedSpecifications);
//   };

//   const addSpecificationValue = (specIndex) => {
//     const updatedSpecifications = [...specifications];
//     updatedSpecifications[specIndex].values.push("");
//     setSpecifications(updatedSpecifications);
//   };

//   const removeSpecificationValue = (specIndex, valueIndex) => {
//     const updatedSpecifications = [...specifications];
//     updatedSpecifications[specIndex].values = updatedSpecifications[
//       specIndex
//     ].values.filter((_, i) => i !== valueIndex);
//     setSpecifications(updatedSpecifications);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formDataToSend = new FormData();
//     formDataToSend.append("title", formData.title);
//     formDataToSend.append("brand", formData.brand);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("regularPrice", formData.regularPrice);
//     formDataToSend.append("salePrice", formData.salePrice);
//     formDataToSend.append("stock", formData.stock);
//     formDataToSend.append("productimage1", formData.productimage1);
//     formDataToSend.append("productimage2", formData.productimage2);
//     formDataToSend.append("productimage3", formData.productimage3);
//     formDataToSend.append("specifications", JSON.stringify(specifications));
//     formDataToSend.append("category", formData.category);
//     formDataToSend.append("subcategory", selectedSubcategory);

//     try {
//       const response = await axios.put(
//         `${apiConfig.baseUrl}${apiConfig.updateProduct}${id}`,
//         formDataToSend,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       setShowModal(true);
//     } catch (error) {
//       console.error("Error sending form data:", error);
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     navigate(`/admin/ViewProduct`);
//   };

//   const handleCancel = () => {
//     navigate(`/admin/ViewProduct`);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "category") {
//       const selectedCategory = parentIds.find(
//         (category) => category.name === value
//       );
//       setSelectedParentId(selectedCategory ? selectedCategory._id : "");
//     }
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">Edit Product: {product.title}</h2>
//       <form
//         onSubmit={handleSubmit}
//         style={{ maxWidth: "600px", margin: "auto" }}
//       >
//         <div className="mb-3">
//           <label className="form-label">Title:</label>
//           <input
//             type="text"
//             className="form-control"
//             name="title"
//             value={formData.title}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="brand">Brand</label>
//           <select
//             id="brand"
//             name="brand"
//             className="form-control"
//             value={formData.brand}
//             onChange={handleInputChange}
//           >
//             <option value="">Select Brand</option>
//             {brands.map((brand) => (
//               <option key={brand.name} value={brand.name}>
//                 {brand.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="category">Category</label>
//           <select
//             id="category"
//             name="category"
//             className="form-control"
//             value={formData.category}
//             onChange={handleChange}
//           >
//             <option value="">Select Category</option>
//             {parentIds.map((parent) => (
//               <option key={parent._id} value={parent.name}>
//                 {parent.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="form-group mb-3">
//           <label htmlFor="subcategory">Subcategory</label>
//           <select
//             id="subcategory"
//             name="subcategory"
//             className="form-control"
//             value={selectedSubcategory}
//             onChange={(e) => setSelectedSubcategory(e.target.value)}
//           >
//             <option value="">Select Subcategory</option>
//             {subcategories.map((subcategory) => (
//               <option key={subcategory._id} value={subcategory.name}>
//                 {subcategory.name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Description:</label>
//           <textarea
//             className="form-control"
//             name="description"
//             value={formData.description}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Regular Price:</label>
//           <input
//             type="text"
//             className="form-control"
//             name="regularPrice"
//             value={formData.regularPrice}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Sale Price:</label>
//           <input
//             type="text"
//             className="form-control"
//             name="salePrice"
//             value={formData.salePrice}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Stock:</label>
//           <input
//             type="text"
//             className="form-control"
//             name="stock"
//             value={formData.stock}
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Product Image 1:</label>
//           <input
//             type="file"
//             className="form-control"
//             name="productimage1"
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Product Image 2:</label>
//           <input
//             type="file"
//             className="form-control"
//             name="productimage2"
//             onChange={handleInputChange}
//           />
//         </div>

//         <div className="mb-3">
//           <label className="form-label">Product Image 3:</label>
//           <input
//             type="file"
//             className="form-control"
//             name="productimage3"
//             onChange={handleInputChange}
//           />
//         </div>

//         <h5>Specifications:</h5>
//         {specifications.map((spec, index) => (
//           <div key={index} className="mb-3">
//             <select
//               className="form-control mb-2"
//               value={spec.key}
//               onChange={(e) =>
//                 handleSpecificationChange(index, {
//                   ...spec,
//                   key: e.target.value,
//                 })
//               }
//             >
//               <option value="">Select Specification Key</option>
//               {specificationKeys.map((key) => (
//                 <option key={key} value={key}>
//                   {key}
//                 </option>
//               ))}
//             </select>
//             {spec.values.map((value, valueIndex) => (
//               <div key={valueIndex} className="input-group mb-2">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="Value"
//                   value={value}
//                   onChange={(e) =>
//                     handleSpecificationValueChange(index, valueIndex, e)
//                   }
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={() => removeSpecificationValue(index, valueIndex)}
//                 >
//                   Remove Value
//                 </button>
//               </div>
//             ))}
//             <button
//               type="button"
//               className="btn btn-danger mb-2"
//               onClick={() => removeSpecification(index)}
//             >
//               Remove Specification
//             </button>
//             <br />
//             <button
//               type="button"
//               className="btn btn-secondary mb-2"
//               onClick={() => addSpecificationValue(index)}
//             >
//               Add Value
//             </button>
//           </div>
//         ))}
//         <button
//           type="button"
//           className="btn btn-primary mb-2"
//           onClick={addSpecification}
//         >
//           Add More Specification
//         </button>
//         <br />

//         <button
//           type="submit"
//           className="btn btn-success"
//           style={{ marginLeft: "370px" }}
//         >
//           Update Product
//         </button>
//         <button
//           type="button"
//           className="btn btn-secondary"
//           style={{ marginLeft: "10px" }}
//           onClick={handleCancel}
//         >
//           Cancel
//         </button>
//       </form>

//       <Modal show={showModal} onHide={closeModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Success</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Product updated successfully!</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={closeModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default EditProducts;

import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

const EditProducts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { product } = location.state;
  const [selectedBrand, setSelectedBrand] = useState("");
  const [specifications, setSpecifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [brands, setBrands] = useState([]);
  const [parentIds, setParentIds] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    brand: "",
    description: "",
    regularPrice: "",
    salePrice: "",
    stock: "",
    productimage1: null,
    productimage2: null,
    productimage3: null,
    category: "",
  });
  const [specificationKeys, setSpecificationKeys] = useState([]);
  const [variations, setVariations] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(response.data);
        if (response.data.length > 0) {
          setSelectedBrand("");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchParentIds = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setParentIds(response.data);
        if (response.data.length > 0) {
          setSelectedParentId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching parent IDs:", error);
      }
    };

    fetchParentIds();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedParentId) {
          const response = await axios.get(
            `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
          );
          setSubcategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchSubcategories();
  }, [selectedParentId]);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
        );
        if (response?.data?.data && Array.isArray(response?.data?.data)) {
          const keys = response.data.data.map((item) => item.key);
          setSpecificationKeys(keys);
        } else {
          console.error(
            "The API response data is either undefined or not an array."
          );
          setSpecificationKeys([]);
        }
      } catch (error) {
        console.error("Error fetching specifications:", error);
        setSpecifications([]);
      }
    };

    fetchSpecifications();
  }, []);

  useEffect(() => {
    if (product) {
      setFormData({
        brand: product.brand,
        title: product.title,
        description: product.description,
        regularPrice: product.regularPrice,
        salePrice: product.salePrice,
        stock: product.stock,
        productimage1: product.productimage1,
        productimage2: product.productimage2,
        productimage3: product.productimage3,
        category: product?.category,
      });

      // Set existing specifications from the product
      if (product.specifications && product.specifications.length > 0) {
        setSpecifications(product.specifications);
        setSpecificationKeys(product.specifications.map((spec) => spec.key));
      }
      // Set existing variations from the product
      if (product.variations && product.variations.length > 0) {
        setVariations(product.variations);
      }

      setSelectedSubcategory(product?.subcategory || "");
    }
  }, [product]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (
      name === "productimage1" ||
      name === "productimage2" ||
      name === "productimage3"
    ) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSpecificationChange = (specIndex, updatedSpec) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex] = updatedSpec;
    setSpecifications(updatedSpecifications);
  };

  const addSpecification = () => {
    setSpecifications([...specifications, { key: "", values: [""] }]);
  };

  const removeSpecification = (index) => {
    setSpecifications(specifications.filter((_, i) => i !== index));
  };

  const handleSpecificationValueChange = (specIndex, valueIndex, e) => {
    const { value } = e.target;
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values[valueIndex] = value;
    setSpecifications(updatedSpecifications);
  };

  const addSpecificationValue = (specIndex) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values.push("");
    setSpecifications(updatedSpecifications);
  };

  const removeSpecificationValue = (specIndex, valueIndex) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[specIndex].values = updatedSpecifications[
      specIndex
    ].values.filter((_, i) => i !== valueIndex);
    setSpecifications(updatedSpecifications);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("brand", formData.brand);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("regularPrice", formData.regularPrice);
    formDataToSend.append("salePrice", formData.salePrice);
    formDataToSend.append("stock", formData.stock);
    formDataToSend.append("productimage1", formData.productimage1);
    formDataToSend.append("productimage2", formData.productimage2);
    formDataToSend.append("productimage3", formData.productimage3);
    formDataToSend.append("specifications", JSON.stringify(specifications));
    formDataToSend.append("variations", JSON.stringify(variations));
    formDataToSend.append("category", formData.category);
    formDataToSend.append("subcategory", selectedSubcategory);
    console.log("formDataToSend", formDataToSend);

    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.updateProduct}${id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setShowModal(true);
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate(`/admin/ViewProduct`);
  };

  const handleCancel = () => {
    navigate(`/admin/ViewProduct`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      const selectedCategory = parentIds.find(
        (category) => category.name === value
      );
      setSelectedParentId(selectedCategory ? selectedCategory._id : "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addVariation = () => {
    setVariations([
      ...variations,
      {
        color: "",
        ram: "",
        rom: "",
        variationTitle: "",
        price: "",
        stock: "",
      },
    ]);
  };

  const removeVariation = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleVariationChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVariations = [...variations];
    updatedVariations[index][name] = value;
    setVariations(updatedVariations);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Edit Product: {product.title}</h2>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "600px", margin: "auto" }}
      >
        <div className="mb-3">
          <label className="form-label">Title:</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="brand">Brand</label>
          <select
            id="brand"
            name="brand"
            className="form-control"
            value={formData.brand}
            onChange={handleInputChange}
          >
            <option value="">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand.name} value={brand.name}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            className="form-control"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {parentIds.map((parent) => (
              <option key={parent._id} value={parent.name}>
                {parent.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            name="subcategory"
            className="form-control"
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory._id} value={subcategory.name}>
                {subcategory.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Description:</label>
          <textarea
            className="form-control"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Regular Price:</label>
          <input
            type="text"
            className="form-control"
            name="regularPrice"
            value={formData.regularPrice}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Sale Price:</label>
          <input
            type="text"
            className="form-control"
            name="salePrice"
            value={formData.salePrice}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Stock:</label>
          <input
            type="text"
            className="form-control"
            name="stock"
            value={formData.stock}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 1:</label>
          <input
            type="file"
            className="form-control"
            name="productimage1"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 2:</label>
          <input
            type="file"
            className="form-control"
            name="productimage2"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Product Image 3:</label>
          <input
            type="file"
            className="form-control"
            name="productimage3"
            onChange={handleInputChange}
          />
        </div>

        <h5>Specifications:</h5>
        {specifications.map((spec, index) => (
          <div key={index} className="mb-3">
            <select
              className="form-control mb-2"
              value={spec.key}
              onChange={(e) =>
                handleSpecificationChange(index, {
                  ...spec,
                  key: e.target.value,
                })
              }
            >
              <option value="">Select Specification Key</option>
              {specificationKeys.map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
            {spec.values.map((value, valueIndex) => (
              <div key={valueIndex} className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  value={value}
                  onChange={(e) =>
                    handleSpecificationValueChange(index, valueIndex, e)
                  }
                />
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeSpecificationValue(index, valueIndex)}
                >
                  Remove Value
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-danger mb-2"
              onClick={() => removeSpecification(index)}
            >
              Remove Specification
            </button>
            <br />
            <button
              type="button"
              className="btn btn-secondary mb-2"
              onClick={() => addSpecificationValue(index)}
            >
              Add Value
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary mb-2"
          onClick={addSpecification}
        >
          Add More Specification
        </button>
        <br />

        <h5>Variations:</h5>
        {variations.map((variation, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="variationTitle"
              name="variationTitle"
              value={variation.variationTitle}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="price"
              name="price"
              value={variation.price}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="color"
              name="color"
              value={variation.color}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ram"
              name="ram"
              value={variation.ram}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="stock"
              name="stock"
              value={variation.stock}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="rom"
              name="rom"
              value={variation.rom}
              onChange={(e) => handleVariationChange(index, e)}
            />
            <Button variant="danger" onClick={() => removeVariation(index)}>
              Remove Variation
            </Button>
          </div>
        ))}
        <Button variant="primary" onClick={addVariation}>
          Add Variation
        </Button>

        <button
          type="submit"
          className="btn btn-success"
          style={{ marginLeft: "370px" }}
        >
          Update Product
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Product updated successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProducts;
