import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from '../../../apiConfig';

const AddDeliveryCost = () => {
  const [shippingCost, setShippingCost] = useState('');
  const [currentCost, setCurrentCost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCostId, setCurrentCostId] = useState(null);

 
  const fetchCurrentCost = async () => {
    try {
      setIsLoading(true);
      console.log('Loading state set to true');

      const response = await axios.get(`${apiConfig.baseUrl}${apiConfig.GetDeliveryCost}`);

      const { _id, shippingCost } = response.data.deliveryCost;
      setCurrentCost(shippingCost);
      setCurrentCostId(_id);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setCurrentCost('Delivery cost not found');
      } else {
        console.error('Error fetching current delivery cost:', error);
      }
    } finally {
      setIsLoading(false);
      console.log('Loading state set to false');
    }
  };
  

  
  const addDeliveryCost = async () => {
    try {
      setIsLoading(true);
      console.log('Loading state set to true');

      const response = await axios.post(`${apiConfig.baseUrl}${apiConfig.AddDeliverCost}`,
        { shippingCost: shippingCost });

      console.log('API Response:', response.data);

      setCurrentCost('');
      setShippingCost('');
      alert('Delivery cost added successfully! Please Click Refresh button' );

    } catch (error) {
      console.error('Error adding delivery cost:', error);

    } finally {
      setIsLoading(false);
      console.log('Loading state set to false');
    }
  };


  const updateDeliveryCost = async () => {
    try {
      setIsLoading(true);
      await axios.put(`${apiConfig.baseUrl}${apiConfig.updateDeliveryCost}`, { shippingCost });
      fetchCurrentCost();
      alert('Delivery cost updated successfully');
    } catch (error) {
      console.error('Error updating delivery cost:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDeliveryCost = async () => {
    if (!currentCostId) return;


    try {
      setIsLoading(true);


      const response = await axios.delete(`${apiConfig.baseUrl}${apiConfig.DeleteDeliverycost}/${currentCostId}`);



      setCurrentCost(null);
      setCurrentCostId(null);
      alert('Delivery cost deleted successfully ');

    } catch (error) {
      console.error('Error deleting delivery cost:', error);

    } finally {
      setIsLoading(false);
      console.log('Loading state set to false');
    }
  };

  useEffect(() => {
    fetchCurrentCost();
  }, []);

  const costStyle = {
    color: currentCost === 'Delivery cost not found' ? 'red' : 'black',
    textAlign: 'center'
  };

  const buttonStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: '#FFF',
    border: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#CCCCCC',
    cursor: 'not-allowed'
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ textAlign: 'center' }}>Manage Delivery Cost</h2>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
          Shipping Cost:
          <input
            type="number"
            value={shippingCost}
            onChange={(e) => setShippingCost(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', flex: '1' }}
          />
        </label>
      </div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center' }}>
        <button onClick={addDeliveryCost} disabled={isLoading} style={isLoading ? disabledButtonStyle : buttonStyle}>
          Add Delivery Cost
        </button>
        <button onClick={updateDeliveryCost} disabled={isLoading || !currentCostId} style={isLoading || !currentCostId ? disabledButtonStyle : buttonStyle}>
          Update Delivery Cost
        </button>
        <button onClick={deleteDeliveryCost} disabled={isLoading || !currentCostId} style={isLoading || !currentCostId ? disabledButtonStyle : buttonStyle}>
          Delete Delivery Cost
        </button>
        <button onClick={fetchCurrentCost} disabled={isLoading} style={isLoading ? disabledButtonStyle : buttonStyle}>
          Refresh
        </button>
      </div>
      <div>
        <h3 style={costStyle}>Current Delivery Cost: {isLoading ? 'Loading...' : currentCost}</h3>
      </div>
    </div>
  );
};

export default AddDeliveryCost;
