

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OrderTracking.css';
import apiConfig from '../../../apiConfig';

const OrderTracking = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [statusOptions] = useState(['processing', 'received', 'shipped', 'delivered', 'cancelled']);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiConfig.baseUrl}/admin/getAllOrders`);
     
        setOrders(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const results = orders.filter(order =>
        order.orderID.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredOrders(results);
    } else {
      setFilteredOrders([]);
    }
  }, [debouncedSearchTerm, orders]);

  useEffect(() => {
    if (selectedOrder) {
      const selectedOrderDetails = orders.find(order => order.orderID === selectedOrder);
      if (selectedOrderDetails) {
        setNewStatus(selectedOrderDetails.status);
      }
    }
  }, [selectedOrder, orders]);

  const handleStatusChange = async () => {
    if (!selectedOrder || !newStatus) return; 
    try {
      await axios.put(`${apiConfig.baseUrl}/admin/order/${selectedOrder}/updateStatus`, { status: newStatus });
      const response = await axios.get(`${apiConfig.baseUrl}/admin/getAllOrders`);
      setOrders(response.data);
      setShowModal(true);  
    } catch (err) {
      console.error('Error updating order status:', err);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectOrder = (e) => {
    setSelectedOrder(e.target.value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="order-tracking">
      <h1>Order Tracking Update</h1>
      <div className="search">
        <input type="text" placeholder="Search by Order ID" value={searchTerm} onChange={handleSearch} />
        {filteredOrders.length > 0 && (
          <div className="search-results">
            <ul>
              {filteredOrders.map(order => (
                <li
                  key={order.orderID}
                  onClick={() => handleSelectOrder(order.orderID)}
                  className={selectedOrder === order.orderID ? 'selected' : ''}
                >
                  <p>Order ID: {order.orderID}</p>
                  <p>Status: {order.status}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="form-group">
        <label>Select Order:</label>
        <select value={selectedOrder} onChange={handleSelectOrder}>
          <option value="">-- Select Order --</option>
          {filteredOrders.map(order => (
            <option key={order.orderID} value={order.orderID}>{order.orderID}</option>
          ))}
        </select>

      </div>
      <div className="form-group">
        <label>Select Status:</label>
        <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
          <option value="">-- Select Status --</option>
          {statusOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <button className="update-button" onClick={handleStatusChange}>Update Status</button>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <p style={{ color: "green" }}>Status updated successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderTracking;
