import React, { useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import apiConfig from "../../apiConfig";

const Category = () => {


  const [categories, setCategories] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [statusChecked, setStatusChecked] = useState(false);



  const addCategory = async (event) => {
 
    event.preventDefault(); 
    
    try {
      if (!categories || !image) {
        alert("Please fill in all fields.");
        return;
      }
  
      const formData = new FormData();
      formData.append('name', categories); 
      formData.append('images', image); 
      
      const token = localStorage.getItem("authToken");

      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.addCategory}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Category added:", response.data);
      setShowModal(true); 
  
      
      setCategories("");
      setImage(null);
      setStatusChecked(false);
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">Category</h1>
      {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Home
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="seo-tags-tab"
            data-bs-toggle="tab"
            data-bs-target="#seo-tags"
            type="button"
            role="tab"
            aria-controls="seo-tags"
            aria-selected="false"
          >
            SEO Tags
          </button>
        </li>
      </ul> */}
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <form onSubmit={addCategory}>
            <div className="form-group mb-3">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={categories}
                onChange={(e) => setCategories(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="image">Image</label>
              <input
                type="file"
                id="image"
                name="images"                                  
                className="form-control"
                accept="image/*" 
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
            {/* <div className="form-group mb-3">
              <label htmlFor="status">Status</label>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="status"
                  name="status"
                  className="form-check-input"
                  checked={statusChecked}
                  onChange={() => setStatusChecked(!statusChecked)}
                />
                <label className="form-check-label" htmlFor="status">
                  Status (0=shown, 1=hidden)
                </label>
              </div>
            </div> */}
            <div className="form-group mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div
          className="tab-pane fade"
          id="seo-tags"
          role="tabpanel"
          aria-labelledby="seo-tags-tab"
        >
          <form>
            <div className="form-group mb-3">
              <label htmlFor="meta_title">Meta Title</label>
              <textarea
                id="meta_title"
                name="meta_title"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="meta_keywords">Meta Keywords</label>
              <textarea
                id="meta_keywords"
                name="meta_keywords"
                className="form-control"
              ></textarea>
            </div>
       
            <div className="form-group mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Category Added</Modal.Title>
        </Modal.Header>
        <Modal.Body>The category has been added successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Category;
